import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Row, Table } from "antd";

import { listParentTags } from "../services";
import { ITag } from "../model";
import * as _ from "lodash";
import { Link, useNavigate } from "react-router-dom";

export const TagList: React.FC = () => {
  const navigate = useNavigate();
  const [innerTagList, setInnerTagList] = useState<ITag[]>([]);
  const [searchValue, setSearchValue] = useState<string>();

  const fetch = async () => {
    if (!innerTagList.length) {
      const tagList = await listParentTags();
      setInnerTagList(tagList);
    }
  };

  const handleClick = () => {
    navigate("/reference-data/tag/create/");
  };

  useEffect(() => {
    fetch();
  }, []);

  const columns: any = [
    {
      title: "Name",
      dataIndex: "uid",
      key: "uid",
      render: (uid: string) => {
        const tag = _.find(innerTagList, { uid });
        if (tag) {
          return (
            <Link to={`/reference-data/tag/${tag.uid}`}>
              {tag.translations.en}
            </Link>
          );
        }
      },
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            Medical specialties & Tags
          </h1>
          <div>
            <Button
              type='primary'
              onClick={handleClick}
              style={{ marginRight: 10 }}
            >
              New tag
            </Button>
          </div>
        </Row>
        <Input.Search
          placeholder='Search tag'
          allowClear
          onSearch={setSearchValue}
        />
        <div style={{ height: 16 }} />
        <Table
          columns={columns}
          dataSource={innerTagList.filter((v) =>
            v.translations.en
              .toLowerCase()
              .startsWith(searchValue?.toLowerCase() ?? "")
          )}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
        />
      </Layout.Content>
    </Layout>
  );
};
