import {
  Button,
  Card,
  Divider,
  notification,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { UploadChangeParam } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
import { IRoom } from "~/model";
import { postRoomIco, postRoomLogo } from "~/services";
import { Flex } from "../../components/shared/global";
import { UploadOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";

type InnerProps = {
  room: IRoom | null;
  variant?: "logo" | "ico";
};

const RoomLogoUploader = ({ room, variant = "logo" }: InnerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    const file = info.file.originFileObj as File;

    setImageFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async () => {
    if (!imageFile || !room) return;

    setLoading(true);
    try {
      if (variant === "logo") await postRoomLogo(room.id, imageFile);
      else if (variant === "ico") await postRoomIco(room.id, imageFile);

      setImageFile(null);
      notification.success({
        message: "Image uploaded successfully!",
        description: "The image has been uploaded to the room",
      });
    } catch (error) {
      notification.error({
        message: "An error occured while uploading the image",
        description: "Please try again later",
      });

      throw error;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (room && room[variant]) setImageUrl(room[variant].url);
  }, [room, variant]);

  if (!room) return null;

  return (
    <Card title={`Room ${capitalize(variant)}`} style={{ width: "100%" }}>
      {imageUrl ? (
        <img
          src={imageUrl || ""}
          style={{
            height: "auto",
            width: "100%",
            maxHeight: 150,
            objectFit: "contain",
            objectPosition: "center",
          }}
          alt={`Room ${variant}`}
        />
      ) : null}
      <Divider />
      <Flex gap={16} flexDirection='column'>
        <Upload
          className='full-width-upload-btn'
          accept='.png, .jpg, .jpeg, .svg'
          maxCount={1}
          showUploadList={false}
          onChange={handleChangeImage}
          customRequest={(payload) => {
            if (payload.onSuccess) payload.onSuccess("ok");
          }}
        >
          <Button
            block
            icon={<UploadOutlined />}
            style={{
              display: "block",
              width: "100%",
            }}
          >
            {"Upload Image"}
          </Button>
        </Upload>
        <Button
          block
          type='primary'
          loading={loading}
          disabled={!imageFile}
          onClick={handleImageUpload}
        >
          {"Confirm"}
        </Button>
      </Flex>
    </Card>
  );
};

export default RoomLogoUploader;
