import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  notification,
} from "antd";
import {
  addNectarHightlights,
  createNectar,
  deleteNectar,
  deleteNectarHightlights,
  getArticle,
  getNectarById,
  publishNectar,
  searchArticlesV2,
  unpublishNectar,
  updateNectar,
} from "../services";
import {
  ContentFormatsEnum,
  IArticle,
  INectar,
  IOrganisation,
  ITag,
  PermissionEnum,
  PermissionLabel,
} from "../model";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { GlobalContext } from "../context/global.context";
import { DebounceSelect } from "./shared/DebounceSelect";
import LoadingLayout from "./shared/LoadingLayout";
import { useDispatch } from "react-redux";
import { fetchData } from "../store/actions";
import { FETCH_HIGHLIGHT_NECTARS, FETCH_TAGS } from "../store/types";
import {
  checkPermission,
  languageOptions,
  searchFilterOptions,
} from "../utils/helpers";
import RoomsAssociator from "./shared/RoomsAssociator";
import { Link } from "react-router-dom";

const NectarEdition = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organisationList, tagList, highlightNectars, user } =
    useContext(GlobalContext);
  const [form] = Form.useForm();
  const [nectar, setNectar] = useState<INectar | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isHighlighted = highlightNectars?.find((hlt) =>
    hlt.highlight.find((ntr) => ntr.nectarId === nectar?._id)
  );

  const fetchNectar = async () => {
    if (!params?.nectarId) return;

    const res = await getNectarById(params.nectarId);
    setNectar({
      ...res,
      associatedArticles: res.associatedArticles.filter(
        (article, index, self) =>
          index === self.findIndex((t) => t._id === article._id)
      ),
    });
  };

  const handleSubmitEdition = async (newStatus: string | boolean = false) => {
    if (!nectar) return;

    const formValues = form.getFieldsValue();
    setIsSubmitting(true);

    if (!params.nectarId) {
      const createdNectar = await createNectar(formValues);
      notification.success({ message: "Nectar created successfully" });
      navigate(`/content-management/nectar/${createdNectar._id}`);
    } else {
      const associatedArticles = nectar?.associatedArticles?.map(
        (article) => article._id
      );

      const payload = {
        ...nectar,
        ...formValues,
        associatedArticles,
      };

      const newNectar = await updateNectar(params.nectarId, payload);

      setNectar({
        ...nectar,
        isPublic: newNectar.isPublic,
        owner: newNectar.owner,
      });

      if (newStatus === "published") {
        setNectar({ ...nectar, meta: { ...nectar.meta, status: "published" } });
        await publishNectar(params.nectarId);
      } else if (newStatus === "draft") {
        setNectar({ ...nectar, meta: { ...nectar.meta, status: "draft" } });
        await unpublishNectar(params.nectarId);
      }

      notification.success({ message: "Nectar updated successfully" });
    }
    setIsSubmitting(false);
  };

  const handleDeleteArticle = (article: IArticle) => {
    if (!nectar) return;

    const associatedArticles = nectar?.associatedArticles?.filter(
      (el) => el._id !== article._id
    );
    setNectar({ ...nectar, associatedArticles });
  };

  const handleSearchArticles = async (searchValue: string) => {
    const { hits } = await searchArticlesV2({
      language: params?.nectarId
        ? nectar?.language
        : form.getFieldValue("language"),
      search: searchValue,
    });
    const articles = hits.hits
      .map((hit) => hit._source)
      .map((article) => ({ ...article.core, _id: article.id }));

    return articles.map((el) => ({ label: el.title, value: el.slug }));
  };

  const handleAddArticle = async (
    selections: { label: string; value: string }[]
  ) => {
    const selection = selections[0];
    const article = await getArticle(selection.value);

    if (!nectar) {
      setNectar({
        ...form.getFieldsValue(),
        associatedArticles: [article],
      });
    } else {
      if (nectar?.associatedArticles?.find((el) => el._id === article._id))
        return;

      const associatedArticles = nectar?.associatedArticles
        ? [article, ...nectar.associatedArticles]
        : [article];

      setNectar({ ...nectar, associatedArticles });
    }
  };

  const handleDeleteNectar = async () => {
    if (!params.nectarId) return;

    await deleteNectar(params.nectarId);
    notification.success({
      message: "Nectar deleted successfully",
    });
    navigate("/content-management/nectar");
  };

  const handlePinHighlight = async () => {
    if (!nectar) return;

    if (isHighlighted) {
      await deleteNectarHightlights(nectar._id);
      notification.success({
        message: "Nectar un-highlighted",
        description: "The nectar has been un-highlighted successfully",
      });

      dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));
      return;
    }

    await addNectarHightlights(nectar._id);
    dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));

    notification.success({
      message: "Nectar highlighted",
      description: "The nectar has been highlighted successfully",
    });
  };

  useEffect(() => {
    if (!tagList.length) dispatch(fetchData(FETCH_TAGS));
    fetchNectar();

    return () => {
      notification.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsArticles: ColumnsType<IArticle> = [
    {
      title: "",
      key: "actions",
      width: 50,
      render: (article) => (
        <Button
          shape='circle'
          size='small'
          type='primary'
          danger
          onClick={() => handleDeleteArticle(article)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string) => (
        <div style={{ color: "#000", fontWeight: 600, fontSize: 13 }}>
          {title}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: ["meta", "status"],
      key: "status",
      render: (status: string) =>
        status === "published" ? (
          <Tag color='green'>Published</Tag>
        ) : (
          <Tag color='red'>Draft</Tag>
        ),
      width: 100,
    },
    {
      title: "Publication date",
      dataIndex: "publication_date",
      key: "publication_date",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
      width: 200,
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 150,
      render: (owner: IOrganisation) => {
        if (!owner) return <span>{"N/A"}</span>;

        return (
          <Link to={`/sensitive-data/organisations/${owner?.uid}`}>
            {owner.name}
          </Link>
        );
      },
    },
  ];

  if ((!nectar && params?.nectarId) || !tagList.length)
    return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Button
          style={{ padding: 0 }}
          type='link'
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1
            style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}
            onClick={() => console.log(nectar)}
          >
            Edit Nectar
          </h1>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag
              style={{
                fontSize: 14,
                padding: "5px 8px",
              }}
              color={nectar?.meta.status === "published" ? "blue" : "orange"}
            >
              {nectar?.meta.status.toLocaleUpperCase()}
            </Tag>
            <Divider type='vertical' />
            <Button
              onClick={() =>
                handleSubmitEdition(
                  nectar?.meta.status === "published" ? "draft" : "published"
                )
              }
              loading={isSubmitting}
            >
              {nectar?.meta.status === "published" ? "Unpublish" : "Publish"}
            </Button>
            <Divider type='vertical' />
            <Button
              type={isHighlighted ? "primary" : "default"}
              icon={isHighlighted ? <StarFilled /> : <StarOutlined />}
              onClick={handlePinHighlight}
            >
              {isHighlighted ? "Un-highlight" : "Highlight"}
            </Button>
            <Divider type='vertical' />
            <Button
              loading={isSubmitting}
              type='primary'
              onClick={() => form.submit()}
            >
              Save changes
            </Button>
            <Divider type='vertical' />
            <Button
              danger
              loading={isSubmitting}
              type='primary'
              onClick={handleDeleteNectar}
              disabled={
                (user || false) &&
                !checkPermission(
                  user,
                  PermissionLabel.Content,
                  PermissionEnum.Delete
                )
              }
            >
              Delete nectar
            </Button>
          </div>
        </Row>

        <div style={{ height: 16 }} />

        <Card>
          <Form
            form={form}
            onFinish={handleSubmitEdition}
            initialValues={
              nectar
                ? {
                    ...nectar,
                    publication_date: moment(nectar.publication_date),
                    owner: nectar?.owner?._id,
                    medical_specialties: nectar.medical_specialties.map(
                      (specialty) => (specialty as ITag)._id
                    ),
                    tags: nectar.tags.map((tag) => (tag as ITag)._id),
                  }
                : undefined
            }
          >
            <Tabs>
              <Tabs.TabPane tab='Content' key='1'>
                <Form.Item name='question' label='Question' required>
                  <Input placeholder='Question' />
                </Form.Item>
                <Form.Item name='answer' label='Answer' required>
                  <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 15 }}
                    placeholder='Answer'
                  />
                </Form.Item>
                <Form.Item name='language' label='Language' required>
                  <Select
                    placeholder='Language'
                    onChange={(value) => {
                      nectar && setNectar({ ...nectar, language: value });
                    }}
                    options={languageOptions}
                  />
                </Form.Item>
                <Form.Item
                  name='publication_date'
                  label='Publication date'
                  required
                >
                  <DatePicker placeholder='Publication date' />
                </Form.Item>
                <Form.Item name='owner' label='Owner' required>
                  <Select
                    disabled={organisationList.length === 0}
                    placeholder='Owner'
                    options={organisationList.map((organisation) => ({
                      label: organisation.name,
                      value: organisation._id,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  name='medical_specialties'
                  label='Medical specialties'
                  required
                >
                  <Select
                    mode='multiple'
                    placeholder='Medical specialties'
                    options={tagList
                      .filter((tag) => !tag.parent)
                      .map((specialty) => ({
                        label: specialty.translations["en"],
                        value: specialty._id,
                      }))}
                    filterOption={searchFilterOptions}
                  />
                </Form.Item>

                <Form.Item name='tags' label='Tags'>
                  <Select
                    mode='multiple'
                    placeholder='Tags'
                    options={tagList
                      .filter((tag) => !!tag.parent)
                      .map((tag) => ({
                        label: `${tag.translations["en"]} (${tag.parent?.translations["en"]})`,
                        value: tag._id,
                      }))}
                    filterOption={searchFilterOptions}
                  />
                </Form.Item>

                <Divider />

                <DebounceSelect
                  style={{ width: "100%", marginBottom: 16 }}
                  mode='multiple'
                  placeholder='Search articles'
                  fetchOptions={handleSearchArticles}
                  value={[]}
                  onChange={(article) => {
                    handleAddArticle(
                      article as unknown as Array<{
                        label: string;
                        value: string;
                      }>
                    );
                  }}
                />
                <Table
                  scroll={{ x: "max-content" }}
                  columns={columnsArticles}
                  dataSource={nectar?.associatedArticles.map((article) => ({
                    ...article,
                    key: article._id,
                  }))}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Rooms' key='2'>
                {nectar && (
                  <RoomsAssociator
                    contentId={nectar._id}
                    type={ContentFormatsEnum.NECTAR}
                    isPublic={nectar?.isPublic}
                    ownerId={nectar?.owner?._id}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default NectarEdition;
