import { IMapping } from ".";
import { IOrganisation, SupportedLanguage } from "../model";

export const createCongressMapping = (
  organisationList?: IOrganisation[]
): { [key: string]: IMapping } => ({
  language: {
    label: "Language",
    type: "select",
    enum: Object.values(SupportedLanguage),
    required: true,
  },
  name: {
    label: "Name",
    type: "text",
    required: true,
  },
  organisations: {
    label: "Organisations",
    type: "multiple",
    enum: organisationList?.map(
      (organisation: IOrganisation) => organisation.name
    ),
    required: true,
  },
  description: {
    label: "Description",
    type: "textarea",
    required: true,
  },
  website: {
    label: "Website",
    type: "text",
    required: false,
  },
});
