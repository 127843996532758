import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
  notification,
} from "antd";
import {
  addNectarHightlights,
  convertNectarQuestion,
  createNectarQuestion,
  deleteNectarHightlights,
  deleteNectarQuestion,
  downloadNectarCsv,
  getListNectar,
  getNectarQuestionList,
  searchNectar,
} from "../services";
import {
  IArticle,
  INectar,
  INectarQuestion,
  IOrganisation,
  IQuery,
  SupportedLanguage,
} from "../model";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/global.context";
import FormItem from "antd/es/form/FormItem";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FireOutlined,
  HistoryOutlined,
  QuestionCircleOutlined,
  StarOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  languageLabel,
  languageOptions,
  searchFilterOptions,
} from "../utils/helpers";
import { Flex } from "./shared/global";
import { DragTable } from "./shared/DragTable";
import { useDispatch } from "react-redux";
import { FETCH_HIGHLIGHT_NECTARS } from "../store/types";
import { fetchData } from "../store/actions";
import axios from "axios";
import FiltersDrawer from "./shared/FiltersDrawer";

const FETCH_LIMIT = 20;

const INIT_FILTERS: IQuery = {
  sortBy: "creationDate",
  sortOrder: "desc",
  limit: FETCH_LIMIT,
  offset: 0,
};

const NectarList = () => {
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    organisationList,
    roomList,
    highlightNectars: highlightList,
  } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [nectarList, setNectarList] = useState<{
    list: INectar[];
    total: number;
  } | null>(null);

  const [questionsList, setQuestionsList] = useState<{
    list: INectarQuestion[];
    total: number;
  } | null>(null);

  const [filters, setFilters] = useState<{
    question: IQuery;
    nectar: IQuery;
  } | null>({
    question: { ...INIT_FILTERS },
    nectar: { ...INIT_FILTERS },
  });

  const [activeTab, setActiveTab] = useState<number>(
    localStorage.getItem("nectarActiveTab")
      ? parseInt(localStorage.getItem("nectarActiveTab") as string)
      : 1
  );
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [loadingAskQuestion, setLoadingAskQuestion] = useState<boolean>(false);
  const [selectedNectar, setSelectedNectar] = useState<string[]>([]);

  const showQuestions = activeTab === 2;

  const fetchNectarList = async () => {
    if (filters?.nectar.search?.length) {
      const term = filters?.nectar.search;
      delete filters?.nectar.search;

      const { docs, meta } = await searchNectar(term, filters?.nectar);
      setNectarList({
        list: docs,
        total: meta.total,
      });
    } else {
      const { docs, meta } = await getListNectar({
        ...filters?.nectar,
      });

      setNectarList({
        list: docs,
        total: meta.total,
      });
    }
  };

  const fetchNectarQuestions = useCallback(async () => {
    const { docs, meta } = await getNectarQuestionList({
      ...filters?.question,
      limit: filters?.question.limit || FETCH_LIMIT,
      offset: filters?.question.offset || 0,
    });

    setQuestionsList({
      list: docs,
      total: meta.total,
    });
  }, [filters]);

  const handleSubmitQuestion = async (values: {
    question: string;
    owner: string;
    language: SupportedLanguage;
  }) => {
    if (form.getFieldValue("question") === "")
      return message.error({ content: "Please enter a question" });
    if (form.getFieldValue("language") === undefined)
      return message.error({ content: "Please select a language" });
    if (form.getFieldValue("owner") === undefined)
      return message.error({ content: "Please select an organisation" });

    setLoadingAskQuestion(true);

    const nectarRequest = await createNectarQuestion(values);

    if (nectarRequest) {
      message.success({
        content: "Question submitted successfully!",
      });
      fetchNectarQuestions();
      setActiveTab(2);
      form.resetFields();
    }

    setLoadingAskQuestion(false);
  };

  const handleDeleteQuestion = async (questionId: string) => {
    await deleteNectarQuestion(questionId);
    message.success({
      content: "Question deleted",
    });
    fetchNectarQuestions();
    fetchNectarList();
  };

  const handleConvertQuestion = async (questionId: string) => {
    const nectar = await convertNectarQuestion(questionId);

    if (nectar?.response?.data?.statusCode === 400)
      return message.error({
        content: "This question has already been converted to nectar",
      });

    message.success({
      content: "Question converted to nectar",
    });
    navigate(`/content-management/nectar/${nectar._id}`);
  };

  const handleChangePage = (page: number, pageSize?: number) => {
    if (!filters) return;

    if (showQuestions)
      setFilters({
        ...filters,
        question: {
          ...filters.question,
          offset: (page - 1) * (pageSize || FETCH_LIMIT),
          limit: pageSize || FETCH_LIMIT,
        },
      });
    else
      setFilters({
        ...filters,
        nectar: {
          ...filters.nectar,
          offset: (page - 1) * (pageSize || FETCH_LIMIT),
          limit: pageSize || FETCH_LIMIT,
        },
      });
  };

  const handleHighlightNectar = async (nectar: INectar) => {
    const nectarId = nectar._id;
    const isHighlighted = highlightList?.find((el) =>
      el.highlight.find((el) => el.nectarId === nectarId)
    );

    if (isHighlighted) {
      await deleteNectarHightlights(nectarId);
      await fetchNectarList();
      dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));

      message.success({
        content: "Highlight removed successfully!",
      });

      return;
    }

    message.loading({ content: "New highlight is coming..." }, 1);
    const nectarRequest = await addNectarHightlights(nectarId);

    if (nectarRequest) {
      await fetchNectarList();
      dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));
      message.success({
        content: "Nectar highlighted successfully!",
      });
    }
  };

  const handleReorderNectar = async (data: INectar[]) => {
    if (!nectarList) return;

    const reorderedNectars = data.sort((a, b) => {
      const aIndex = data.findIndex((el) => el._id === a._id);
      const bIndex = data.findIndex((el) => el._id === b._id);

      return aIndex - bIndex;
    });

    const nectarOnlyList = nectarList?.list.filter(
      (nectar) =>
        !highlightList?.some((el) =>
          el.highlight.find((el) => el.nectarId === nectar._id)
        )
    );
    const newNectarList =
      nectarOnlyList && nectarList
        ? [...reorderedNectars, ...nectarOnlyList]
        : nectarList.list;

    setNectarList({
      list: newNectarList,
      total: nectarList.total,
    });

    message.warning("Nectars reordering is not yet implemented");
  };

  const handleChangeTabPane = (key: string) => {
    setActiveTab(parseInt(key));
    localStorage.setItem("nectarActiveTab", key);
  };

  const handleCheckNectar = (selectedRowKeys: React.Key[]) => {
    const nectarIds = nectarList?.list.map((el) => el._id);

    const selection = [
      ...(selectedRowKeys as string[]),
      ...selectedNectar.filter((el) => !nectarIds?.includes(el)),
    ];

    setSelectedNectar(selection);
  };

  const handleDownloadNectarCsv = async () => {
    try {
      const nectarCsv = await downloadNectarCsv(selectedNectar);
      const blobConfig = new Blob([nectarCsv], {
        type: "text/csv;charset=utf-8",
      });
      const blobUrl = URL.createObjectURL(blobConfig);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "nectars.csv";

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred",
          description: error.response?.data?.message,
        });
    }
  };

  useEffect(() => {
    if (!filters) return;

    fetchNectarList();
  }, [filters?.nectar]);

  useEffect(() => {
    if (!filters) return;

    fetchNectarQuestions();
  }, [filters?.question]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const language = (query.get("language") as SupportedLanguage) || undefined;

    if (!filters) {
      setFilters({
        question: { ...INIT_FILTERS, language },
        nectar: { ...INIT_FILTERS, language },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      fetchNectarQuestions();
    }, 5000);

    if (!showQuestions) clearInterval(intervalRef.current);

    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [fetchNectarQuestions, showQuestions]);

  const columnsQuestion: ColumnsType<INectarQuestion> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_, nectarQuestion) =>
        !nectarQuestion.answer ? (
          nectarQuestion?.errorMessage ? (
            <Tooltip title={nectarQuestion.errorMessage}>
              <Tag icon={<CloseOutlined />} color='red'>
                Error
              </Tag>
            </Tooltip>
          ) : (
            <Tag color='orange' icon={<SyncOutlined spin />}>
              In progress
            </Tag>
          )
        ) : (
          <Tag icon={<CheckOutlined />} color='green'>
            Done
          </Tag>
        ),
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (question: string, nectarQuestion) =>
        nectarQuestion.answer ? (
          <Link
            to={`/content-management/nectar/question/${nectarQuestion._id}`}
          >
            {question}
          </Link>
        ) : (
          question
        ),
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      width: 100,
      render: (language: SupportedLanguage) => languageLabel[language],
    },
    {
      title: "Organisation",
      dataIndex: ["organisation", "name"],
      key: "organisation",
      width: 300,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 180,
      render: (_, nectarQuestion) => (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Button
            disabled={!nectarQuestion.answer}
            icon={<SyncOutlined />}
            onClick={() => handleConvertQuestion(nectarQuestion._id)}
          >
            Convert
          </Button>
          <Button
            danger
            type='primary'
            icon={<DeleteOutlined />}
            onClick={() =>
              handleDeleteQuestion((nectarQuestion as INectarQuestion).id)
            }
          />
        </div>
      ),
    },
  ];

  const columnsNectar: ColumnsType<INectar> = [
    {
      title: "Status",
      dataIndex: ["meta", "status"],
      key: "status",
      width: 60,
      render: (status: string) => (
        <Tag
          color={status === "published" ? "geekblue" : "orange"}
          style={{ marginLeft: 12 }}
        >
          {status?.toLocaleUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Title",
      dataIndex: "question",
      key: "question",
      width: 250,
      render: (title: string, nectar: INectar | INectarQuestion) => (
        <Link to={`/content-management/nectar/${nectar._id}`}>{title}</Link>
      ),
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: ["owner"],
      key: "owner",
      width: 150,
      render: (owner: IOrganisation) => owner?.name || "N/A",
    },
    {
      title: "Language",
      dataIndex: ["language"],
      key: "language",
      width: 100,
      render: (language: SupportedLanguage) => languageLabel[language],
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      width: 400,
      render: (answer: string) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {answer}
        </div>
      ),
    },
    {
      title: "Creation date",
      dataIndex: ["meta", "creationDate"],
      key: "creationDate",
      width: 150,
      render: (creationDate: string) =>
        moment(creationDate).format("MM/DD/YYYY"),
    },
    {
      title: "Sources",
      dataIndex: ["associatedArticles"],
      key: "sources",
      width: 100,
      render: (associatedArticles: IArticle[]) =>
        `${associatedArticles?.length} articles`,
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 30,
      align: "center",
      render: (_, nectar) => {
        const isHighlighted = !!highlightList?.some((el) =>
          el.highlight.find((el) => el.nectarId === nectar._id)
        );

        return (
          <Tooltip
            title={
              isHighlighted ? "Remove from highlights" : "Highlight this nectar"
            }
          >
            <Button
              type={isHighlighted ? "primary" : "default"}
              icon={isHighlighted ? <CloseOutlined /> : <StarOutlined />}
              onClick={() => handleHighlightNectar(nectar)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const MemoizedTableFilters = useMemo(
    () => (
      <FiltersDrawer
        fields={
          ["languages"].concat(
            showQuestions
              ? []
              : [
                  "owners",
                  "status",
                  "rooms",
                  "search",
                  "isPublic",
                  "medicalSpecialties",
                  "tags",
                ]
          ) as unknown as string[]
        }
        defaultValues={{
          offset: INIT_FILTERS.offset,
          limit: INIT_FILTERS.limit,
        }}
        onChange={(values) =>
          setFilters(
            showQuestions
              ? {
                  question: { ...values },
                  nectar: { ...filters?.nectar },
                }
              : {
                  nectar: { ...values },
                  question: { ...filters?.question },
                }
          )
        }
      >
        {showQuestions ? (
          <Form.Item name='organisations'>
            <Select
              filterOption={searchFilterOptions}
              options={organisationList.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
              mode='multiple'
              placeholder='Filter by organisations'
            />
          </Form.Item>
        ) : null}
      </FiltersDrawer>
    ),
    [showQuestions]
  );

  if (!filters) return null;

  return (
    <Layout>
      <Layout.Content
        style={{
          boxSizing: "border-box",
          height: "100vh",
          paddingBottom: 0,
        }}
      >
        <Drawer
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          title='Filter Nectars & Questios'
        >
          <Flex flexDirection='column' gap={16}>
            <Select
              options={languageOptions}
              placeholder='Language'
              allowClear
              onChange={(language) =>
                setFilters(
                  showQuestions
                    ? {
                        ...filters,
                        question: { ...filters.question, language },
                      }
                    : { ...filters, nectar: { ...filters.nectar, language } }
                )
              }
              value={filters[showQuestions ? "question" : "nectar"].language}
              style={{ width: "100%" }}
            />
            <Select
              options={organisationList.map((organisation) => ({
                label: organisation.name,
                value: organisation._id,
              }))}
              style={{ width: "100%" }}
              placeholder={"Organisation"}
              allowClear
              onChange={(owner) =>
                setFilters(
                  showQuestions
                    ? { ...filters, question: { ...filters.question, owner } }
                    : { ...filters, nectar: { ...filters.nectar, owner } }
                )
              }
              value={filters[showQuestions ? "question" : "nectar"].owner}
            />

            <Select
              options={roomList
                ?.filter((room) =>
                  filters[showQuestions ? "question" : "nectar"].owner
                    ? room.organisation._id ===
                      filters[showQuestions ? "question" : "nectar"].owner
                    : true
                )
                .map((room) => ({
                  label: (
                    <div>
                      {room.name} <small>{`(${room.organisation.name})`}</small>
                    </div>
                  ),
                  value: room.id,
                }))}
              style={{ width: "100%" }}
              placeholder={"Room"}
              allowClear
              onChange={(room) =>
                setFilters(
                  showQuestions
                    ? { ...filters, question: { ...filters.question, room } }
                    : { ...filters, nectar: { ...filters.nectar, room } }
                )
              }
              value={filters[showQuestions ? "question" : "nectar"].room}
            />
          </Flex>
        </Drawer>
        <Tabs
          centered
          activeKey={activeTab.toString()}
          onChange={handleChangeTabPane}
          tabBarStyle={{
            background: "#fff",
            paddingTop: 8,
          }}
        >
          <Tabs.TabPane
            tab={
              <h3>
                <QuestionCircleOutlined />
                {"Ask JuisciGPT"}
              </h3>
            }
            key='1'
            style={{
              padding: "20px 50px 20px",
            }}
          >
            <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Ask JuisciGPT</h1>
            <Divider />
            <Form
              form={form}
              initialValues={{
                owner: organisationList.find((org) => org.uid === "juisci")
                  ?._id,
              }}
              onFinish={handleSubmitQuestion}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                width: "100%",
              }}
            >
              <Form.Item name='question' style={{ width: "100%" }}>
                <Input size='large' placeholder='Ask a question to the LLM' />
              </Form.Item>
              <FormItem name='language'>
                <Select
                  size='large'
                  placeholder='Language'
                  options={languageOptions}
                />
              </FormItem>
              <Form.Item name='owner'>
                <Select
                  style={{ width: 200 }}
                  placeholder='Owner'
                  size='large'
                  defaultValue={
                    organisationList.find((org) => org.uid === "juisci")?._id
                  }
                  options={organisationList.map((organisation) => ({
                    label: organisation.name,
                    value: organisation._id,
                  }))}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  loading={loadingAskQuestion}
                >
                  Submit question
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <h3>
                <HistoryOutlined />
                {"History"}
              </h3>
            }
            key='2'
            style={{
              padding: "20px 50px 0px",
            }}
          >
            <Flex align='center' justify='space-between' gap={16}>
              <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
                Questions
              </h1>
              {MemoizedTableFilters}
            </Flex>
            <Divider />
            <Table
              size='small'
              scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
              pagination={{
                pageSizeOptions: [10, 20, 50, 100],
                defaultPageSize: 20,
                total: questionsList?.total,
                showSizeChanger: true,
                onChange: (page, pageSize) => handleChangePage(page, pageSize),
              }}
              columns={columnsQuestion}
              loading={!questionsList}
              dataSource={
                questionsList
                  ? questionsList.list.map((nectar) => ({
                      key: nectar._id,
                      ...nectar,
                    }))
                  : []
              }
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <h3>
                <FireOutlined />
                {"Nectars"}
              </h3>
            }
            key='3'
            style={{
              padding: "20px 50px 0px",
            }}
          >
            <Flex align='center' justify='space-between' gap={64}>
              <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
                Nectars
              </h1>
              <Flex gap={16} align='center' justify='end'>
                {MemoizedTableFilters}
                <Divider type='vertical' />
                <Button
                  type='primary'
                  icon={<DownloadOutlined />}
                  onClick={handleDownloadNectarCsv}
                  disabled={!selectedNectar.length}
                >
                  Download CSV
                </Button>
              </Flex>
            </Flex>
            <Divider />

            <Table
              size='small'
              scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 25,
                total: nectarList?.total,
                showSizeChanger: true,
                onChange: (page, pageSize) => handleChangePage(page, pageSize),
              }}
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedNectar,
                onChange: handleCheckNectar,
              }}
              columns={columnsNectar}
              loading={!nectarList}
              dataSource={
                nectarList
                  ? nectarList.list
                      .map((nectar) => ({
                        key: nectar._id,
                        ...nectar,
                        highlight: !!highlightList?.some((el) =>
                          el.highlight.find((el) => el.nectarId === nectar._id)
                        ),
                      }))
                      .filter((el) => !el.highlight)
                  : []
              }
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <h3>
                <StarOutlined />
                {"Highlighted Nectars"}
              </h3>
            }
            key='4'
            style={{
              padding: "20px 50px 0px",
            }}
          >
            <Flex align='center' justify='space-between' gap={16}>
              <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
                Nectars
              </h1>
              {MemoizedTableFilters}
            </Flex>
            <Divider />

            <DragTable
              setData={handleReorderNectar}
              scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                total: nectarList?.total,
                showSizeChanger: true,
                onChange: (page, pageSize) => handleChangePage(page, pageSize),
              }}
              columns={columnsNectar}
              loading={!nectarList}
              dataSource={
                nectarList
                  ? nectarList.list
                      .map((nectar) => ({
                        key: nectar._id,
                        ...nectar,
                        highlight: !!highlightList?.some((el) =>
                          el.highlight.find((el) => el.nectarId === nectar._id)
                        ),
                      }))
                      .filter((el) => el.highlight)
                  : []
              }
            />
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  );
};

export default NectarList;
