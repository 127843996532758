import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  List,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Tag,
} from "antd";

import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import {
  createCrawling,
  deleteCrawling,
  deleteCrawlingItems,
  getCrawling,
  parseCrawlingItems,
  updateCrawling,
  uploadCrawlingItemPdf,
} from "../services";
import { ICrawling, ICrawlingItem, SupportedLanguage } from "../model";
import { createCrawlingMapping } from "../config/createCrawlingMapping";
import { FormMapping } from "./shared/FormMapping";
import {
  CrawlingSourceEnum,
  ICreateCrawlingDto,
} from "../model/dto/crawling-dto";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import moment from "moment";

import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Flex, Spacer } from "./shared/global";
import LoadingLayout from "./shared/LoadingLayout";

const { confirm } = Modal;

export const CrawlingEdition: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const crawlingId = params.id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [innerCrawling, setInnerCrawling] = useState<ICrawling>();
  const [items, setItems] = useState<string[]>([]);
  const [form] = Form.useForm();
  const fetchIntervalRef = useRef<NodeJS.Timeout>();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [] },
  });

  const handleCreation = async (values: ICreateCrawlingDto) => {
    if (values.sources) {
      const data = {
        ...values,
        from: moment(values?.period?.[0]).toDate(),
        to: moment(values?.period?.[1]).toDate(),
        publicationTypes: (values.publicationTypes as unknown as string).split(
          ";"
        ),
        authors: (values.authors as unknown as string).split(";"),
        titles: (values.titles as unknown as string).split(";"),
        keywords: (values.keywords as unknown as string).split(";"),
      };

      delete data?.period;

      try {
        await createCrawling({ ...data, languages: [SupportedLanguage.EN] });
        notification.success({
          message: "Crawling created",
          description: "Crawling was succesfully created.",
        });
        navigate("/content-sources/crawling/");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        notification.error({
          message: "Crawling creation failed",
          description: error.response.data.message,
        });
        throw error;
      }
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { checked, value } = e.target;

    if (checked) {
      setItems([...items, value?._id]);
    } else {
      setItems(items.filter((item: string) => item !== value?._id));
    }
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Parsing created",
      description: "Parsings were succesfully created for selected items.",
    });
  };

  const handlePdfUpload = async (doi: string) => {
    setIsLoading(true);
    if (acceptedFiles.length) {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("doi", doi);

      const response = await uploadCrawlingItemPdf(formData);
      if (response && innerCrawling?.results) {
        setIsLoading(false);
        const payload = {
          results: innerCrawling.results.map((crawlingItem: ICrawlingItem) => {
            return crawlingItem.doi === doi
              ? {
                  ...crawlingItem,
                  link_pdf: `https://parser.juisci.com/api/v1/download_pdf?doi=${doi}`,
                }
              : crawlingItem;
          }),
        };
        if (crawlingId) {
          const crawling = await updateCrawling(crawlingId, payload);
          if (crawling) setIsLoading(false);
        }
      }
    }
  };

  const handleItemParsing = async () => {
    if (crawlingId) {
      const createdParsings = await parseCrawlingItems(crawlingId, items);
      if (createdParsings) {
        openNotificationSuccess();
      }
    }
  };

  const handleItemDeletion = async () => {
    if (crawlingId) {
      const updatedCrawling = await deleteCrawlingItems(crawlingId, items);
      if (updatedCrawling) {
        setInnerCrawling(updatedCrawling);
      }
    }
  };

  const handleDeletion = async () => {
    if (crawlingId)
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this crawling?</p>,
        async onOk() {
          const deletedCrawling = await deleteCrawling(crawlingId);
          if (deletedCrawling) {
            navigate("/content-sources/crawling/");
          }
        },
        onCancel() {},
      });
  };

  useEffect(() => {
    const fetch = async () => {
      if (crawlingId) {
        const crawling = await getCrawling(crawlingId);
        if (crawling) setInnerCrawling(crawling);
        if (crawling?.meta.status !== "pending")
          clearInterval(fetchIntervalRef.current);
      }
    };

    fetch();

    fetchIntervalRef.current = setInterval(() => fetch(), 2000);

    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [crawlingId]);

  if (!innerCrawling && !location.pathname.includes("/create"))
    return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <Flex align='center' justify='space-between'>
        <Button
          type='link'
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Tag
          color={
            innerCrawling?.meta.status === "pending"
              ? "warning"
              : innerCrawling?.meta.status === "success"
              ? "success"
              : "error"
          }
        >
          {innerCrawling?.meta.status.toLocaleUpperCase()}
        </Tag>
      </Flex>
      <Spacer />
      <h1>{innerCrawling?.title}</h1>
      <Spacer />
      <Card>
        <Row gutter={16}>
          <Col span={8}>
            <h2 style={{ marginBottom: "20px", fontWeight: 700 }}>
              Parameters
            </h2>
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout='vertical'
                name='crawling_creation'
                fields={[
                  ...Object.keys(createCrawlingMapping).map((key: string) => ({
                    name: [key],
                    value:
                      innerCrawling &&
                      Object.keys(innerCrawling?.request).includes(key)
                        ? Array.isArray((innerCrawling.request as never)[key])
                          ? (
                              (innerCrawling.request as never)[key] as string[]
                            ).join(";")
                          : (innerCrawling.request as never)[key]
                          ? (innerCrawling.request as never)[key]
                          : ""
                        : "",
                  })),
                  {
                    name: "journals",
                    value: innerCrawling?.request.journals || [],
                  },
                  {
                    name: "sources",
                    value: innerCrawling?.sources || [],
                  },
                  {
                    name: "title",
                    value: innerCrawling?.title || "",
                  },
                  {
                    name: "limit",
                    value: innerCrawling?.request?.nb_articles || "",
                  },
                  {
                    name: "from",
                    value: innerCrawling
                      ? moment(innerCrawling?.request?.dates?.from)
                      : null,
                  },
                  {
                    name: "to",
                    value: innerCrawling
                      ? moment(innerCrawling?.request?.dates?.to)
                      : null,
                  },
                  {
                    name: "period",
                    value: innerCrawling
                      ? [
                          moment(innerCrawling?.request?.dates?.from),
                          moment(innerCrawling?.request?.dates?.to),
                        ]
                      : null,
                  },
                  {
                    name: "languages",
                    value: innerCrawling?.request?.languages || [],
                  },
                  {
                    name: "custom",
                    value: innerCrawling?.request?.custom_query || "",
                  },
                ]}
                onFinish={handleCreation}
              >
                <>
                  <Form.Item
                    key={"sources"}
                    label={"Sources"}
                    name={"sources"}
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: `At least one source is required.`,
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      size='middle'
                      placeholder='Please select source'
                      disabled={!!crawlingId}
                      options={Object.entries(CrawlingSourceEnum).map(
                        ([key, value]) => ({
                          label: value,
                          value: key,
                        })
                      )}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    key={"period"}
                    label={"Period"}
                    name={"period"}
                    required={true}
                  >
                    <DatePicker.RangePicker
                      disabled={!!crawlingId}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  {Object.keys(createCrawlingMapping).map((key: string) => (
                    <FormMapping
                      key={key}
                      formMapping={createCrawlingMapping}
                      id={key}
                      disableAll={!!crawlingId}
                    />
                  ))}
                </>
                <Row justify='end'>
                  <div style={{ height: 16 }} />
                  <Form.Item>
                    {!crawlingId ? (
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='article-form-button'
                        size='large'
                        style={{ width: 200 }}
                      >
                        Crawl
                      </Button>
                    ) : null}
                  </Form.Item>
                </Row>
              </Form>
            </Spin>
          </Col>
          <Col span={16}>
            <Flex justify='space-between' align='center'>
              <h2 style={{ margin: 0, fontWeight: 700 }}>Results</h2>
              <div>
                <Flex gap={8}>
                  <Button onClick={handleItemParsing} disabled={!items.length}>
                    Parse
                  </Button>
                  <Button
                    onClick={handleItemDeletion}
                    disabled={!items.length}
                    danger
                  >
                    Delete
                  </Button>
                </Flex>
              </div>
            </Flex>
            <Spacer />
            <List
              bordered
              loading={innerCrawling?.meta.status === "pending" || isLoading}
              dataSource={innerCrawling?.results?.map(
                (crawlingItem: ICrawlingItem) => ({
                  title: crawlingItem?.title,
                  linkPdf: crawlingItem?.link_pdf,
                  doi: crawlingItem?.doi,
                  _id: crawlingItem?._id,
                })
              )}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox
                    style={{ marginRight: "20px" }}
                    value={item}
                    onChange={handleCheckboxChange}
                    disabled={!item.linkPdf || item.linkPdf === "Unavailable"}
                  />
                  <a
                    href={item?.doi || ""}
                    target='_blank'
                    type='link'
                    style={{
                      whiteSpace: "normal",
                      height: "auto",
                      width: "100%",
                      textAlign: "left",
                      marginRight: 16,
                      color:
                        !item.linkPdf || item.linkPdf === "Unavailable"
                          ? "gray"
                          : undefined,
                    }}
                  >
                    {item.title}
                  </a>

                  {item.doi &&
                  item.linkPdf &&
                  item.linkPdf !== "Unavailable" ? (
                    <Button download href={item.linkPdf} target='_blank'>
                      <DownloadOutlined />
                    </Button>
                  ) : item.doi ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: 150,
                        gap: 4,
                      }}
                    >
                      <div
                        {...getRootProps({
                          className: "dropzone",
                          maxFiles: 1,
                          style: {
                            width: 150,
                          },
                        })}
                      >
                        <input
                          {...getInputProps({
                            style: {
                              width: 150,
                              height: 32,
                              display: "block",
                              margin: "0 auto",
                            },
                          })}
                        />
                      </div>
                      <Button onClick={() => handlePdfUpload(item.doi)}>
                        Upload PDF
                      </Button>
                    </div>
                  ) : null}
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row justify='end'>
          <div style={{ height: 16 }} />
          {crawlingId ? (
            <Button
              size='large'
              danger
              style={{
                marginBottom: "20px",
                width: 200,
              }}
              onClick={handleDeletion}
            >
              Delete crawling
            </Button>
          ) : null}
        </Row>
      </Card>
    </div>
  );
};
