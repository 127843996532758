import { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button, Card, Modal, notification, Tag, Tooltip } from "antd";
import { FeedbackStatusEnum, IVideoRequest } from "~/model";
import { Flex } from "../shared/global";
import { CloseOutlined } from "@ant-design/icons";
import { deleteFeedbackVideoRequest } from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";

type InnerProps = {
  requestId?: string;
  video: IVideoRequest["video"];
  setVideoRequest: React.Dispatch<React.SetStateAction<IVideoRequest | null>>;
};

const CardCommentVideo = ({
  requestId,
  video,
  setVideoRequest,
}: InnerProps) => {
  const [showDone, setShowDone] = useState(false);

  const handleDeleteFeedback = async (feedbackId: string) => {
    if (!requestId) return;

    Modal.confirm({
      title: "Are you sure you want to delete this comment?",
      onOk: async () => {
        try {
          const newRequest = await deleteFeedbackVideoRequest({
            requestId,
            feedbackId,
          });
          setVideoRequest((prev) =>
            prev
              ? {
                  ...prev,
                  video: {
                    ...prev.video,
                    feedbacks: [...newRequest.video.feedbacks],
                  },
                }
              : newRequest
          );

          notification.success({
            message: "Success",
            description: "Comment deleted successfully",
          });
        } catch (error) {
          notification.error({
            message: "Error",
            description:
              getRequestErrorMessage(error) ||
              "An error occurred while deleting the comment",
          });
        }
      },
    });
  };

  const totalDone = video?.feedbacks
    ? [...video.feedbacks]?.filter((c) => c.status === FeedbackStatusEnum.DONE)
        ?.length
    : 0;

  const totalCancel = video?.feedbacks
    ? [...video.feedbacks]?.filter(
        (c) => c.status === FeedbackStatusEnum.CANCEL
      )?.length
    : 0;

  return (
    <StyledCard
      title={
        <Flex align='center' justify='space-between'>
          <div>{"Comments"}</div>
          <Button
            size='small'
            type='link'
            onClick={() => setShowDone(!showDone)}
          >
            {showDone
              ? "Hide done comments"
              : `Show ${totalDone} done comments`}
          </Button>
        </Flex>
      }
    >
      {video.feedbacks?.length
        ? [...video.feedbacks]
            ?.filter((c) => c.status !== FeedbackStatusEnum.CANCEL)
            ?.filter((c) =>
              showDone ? true : c.status !== FeedbackStatusEnum.DONE
            )
            .map((feedback) => (
              <div
                key={feedback._id}
                className={`comment ${
                  feedback.authorRole === "juisci" ? "isJuisci" : "isClient"
                } ${feedback.status.toLowerCase()}  
                `}
              >
                <div style={{ width: "100%" }}>
                  <Flex
                    align='center'
                    justify='space-between'
                    className='header'
                  >
                    <div>
                      <Tag color='volcano'>{feedback.timer || "00:00"}</Tag>
                      <Tooltip
                        title={moment(feedback.createdAt).format(
                          "YYYY/MM/DD [at] HH:mm"
                        )}
                      >
                        <span>{moment(feedback.createdAt).fromNow()}</span>
                      </Tooltip>
                    </div>
                    {feedback.authorRole === "juisci" && (
                      <Button
                        shape='circle'
                        icon={<CloseOutlined />}
                        size='small'
                        onClick={() => handleDeleteFeedback(feedback._id)}
                      />
                    )}
                  </Flex>
                  <p className='msg-content'>{feedback.message}</p>
                  <small>
                    {feedback.status === FeedbackStatusEnum.DONE
                      ? "✅ Done"
                      : "⏳ In progress"}
                  </small>
                </div>
              </div>
            ))
        : "No comments yet"}
      {totalDone + totalCancel === video?.feedbacks?.length && !showDone && (
        <p className='no-comment-text'>
          {"No pending comments for the moment..."}
        </p>
      )}
    </StyledCard>
  );
};

export default CardCommentVideo;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .comment {
    border: 1px solid #f0f0f0;
    padding: 16px;
    margin: 8px 0;
    width: 90%;

    &.isJuisci {
      background: #fff3ce;
      border-radius: 8px 8px 0 8px;
      align-self: flex-end;
    }

    &.isClient {
      background: #f0f0f0;
      border-radius: 8px 8px 8px 0;
    }

    &.done {
      opacity: 0.5;
    }

    .header {
      font-size: 11px;
      color: #667;
      padding-bottom: 12px;
    }

    .msg-content {
      margin: 8px 0;
      white-space: pre-wrap;
    }
    .no-comment-text {
      margin: 8px;
      text-align: center;
      font-style: italic;
      opacity: 0.5;
    }
  }
`;
