import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalContext } from "../context/global.context";
import {
  Button,
  Divider,
  Layout,
  Pagination,
  Row,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import { listInfographics } from "../services";
import {
  IInfographic,
  IJournal,
  IOrganisation,
  IQuery,
  ITag,
  ICompany,
  ISorter,
} from "../model";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { BarChartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import FiltersDrawer, {
  FiltersDrawerValuesModel,
} from "./shared/FiltersDrawer";
import { useCheckFiltersSaved } from "~/utils/hooks";
import Fuse from "fuse.js";

export const InfographicList: React.FC = () => {
  const { tagList } = useContext(GlobalContext) as IGlobalContext;

  const [innerInfographicList, setInnerInfographicList] = useState<
    IInfographic[]
  >([]);

  const [filters, setFilters] = useState<FiltersDrawerValuesModel>();
  const [sorter, setSorter] = useState<ISorter>();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [metricsMode, setMetricsMode] = useState<boolean>(false);

  const hasSavedFilters = useCheckFiltersSaved();

  const fetchInfographics = async (limit?: number, offset?: number) => {
    const medical_specialties = tagList.find((tag) =>
      filters?.medicalSpecialties?.includes(tag._id)
    )?.uid;

    if (!filters && hasSavedFilters) return; // Prevent fetching if filters are saved

    if (filters?.search) return handleSearchInfographics();

    delete filters?.medicalSpecialties;

    const query: IQuery = {
      ...sorter,
      ...filters,
      content_format: "Infographic",
      limit: limit || 100,
      offset: offset || 0,
      medical_specialties,
    };

    setIsLoading(true);
    const infographicList = await listInfographics(query);

    if (infographicList) {
      setInnerInfographicList(infographicList.docs);
      setCount(infographicList.meta.total);
    }

    setIsLoading(false);
  };

  const handleSearchInfographics = () => {
    if (!filters?.search) return setInnerInfographicList(innerInfographicList);

    const fuseOptions = {
      includeScore: true,
      threshold: 0.45,
      keys: ["title"],
      // isCaseSensitive: false,
      // includeMatches: false,
      // findAllMatches: false,
      // distance: 100,
    };

    const fuse = new Fuse(innerInfographicList, fuseOptions);

    const result = fuse.search(filters.search);

    setInnerInfographicList(result.map((r) => r.item));
  };

  const handleChangeFilters = (filters: FiltersDrawerValuesModel) => {
    setFilters(filters);
    handlePaginationChange(1);
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: any,
    sorter: any
  ) => {
    const sortOrder = sorter.order === "ascend" ? "asc" : "desc";

    if (sorter.column?.title === "Creation date")
      setSorter({ sortBy: "creationDate", sortOrder });

    if (sorter.column?.title === "Publication date")
      setSorter({ sortBy: "publicationDate", sortOrder });
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchInfographics(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
    setPage(page);
  };

  useEffect(() => {
    fetchInfographics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter, filters]);

  const columns: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 480,
      render: (text: string) => {
        const infographic = _.find(innerInfographicList, { title: text });
        if (infographic) {
          return (
            <Link to={`/content-management/infographic/${infographic._id}`}>
              {infographic.title}
            </Link>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "title",
      key: "title",
      width: 160,
      render: (text: string) => {
        const infographic = _.find(innerInfographicList, { title: text });
        if (infographic) {
          return (
            <Tag
              color={
                infographic?.meta
                  ? infographic?.meta?.status === "draft"
                    ? "warning"
                    : "success"
                  : (infographic as any)?.status === "draft"
                  ? "warning"
                  : "success"
              }
            >
              {infographic?.meta
                ? infographic?.meta?.status?.toLocaleUpperCase()
                : (infographic as any)?.status?.toLocaleUpperCase()}
            </Tag>
          );
        }
      },
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 240,
      render: (owner: IOrganisation) => owner?.name || "N/A",
    },
    {
      title: "Creation date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text: string, record: IInfographic) => {
        return new Date(record?.meta?.creationDate)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text: string, record: IInfographic) => {
        return new Date(record?.publication_date)?.toLocaleDateString();
      },
    },
    {
      title: "Infographic format",
      dataIndex: "infographicFormat",
      key: "infographicFormat",
      width: 160,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 160,
      render: (company: ICompany) => {
        return company?.name;
      },
    },
    {
      title: "Medical Specialties",
      dataIndex: "medical_specialties",
      key: "medical_specialties",
      width: 480,
      render: (spes: ITag[]) =>
        spes
          ?.map(
            (spe: ITag) =>
              spe.translations &&
              spe.translations[filters?.languages?.[0] || "en"]
          )
          .join(", "),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 480,
      render: (tags: ITag[]) =>
        tags
          ?.map(
            (tag: ITag) =>
              tag.translations &&
              tag.translations[filters?.languages?.[0] || "en"]
          )
          .join(", "),
    },
    {
      title: "Journal",
      dataIndex: "journal",
      key: "journal",
      width: 240,
      render: (journal: IJournal) => {
        return journal?.name;
      },
    },
  ];

  const columnsMetrics: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 480,
      render: (_text: string, record: IInfographic) => (
        <Link to={`/content-management/infographic/${record._id}`}>
          {record.title}
        </Link>
      ),
    },
    {
      title: "Creation date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text: string, record: IInfographic) => {
        return new Date(record?.meta?.creationDate)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text: string, record: IInfographic) => {
        return new Date(record?.publication_date)?.toLocaleDateString();
      },
    },
    {
      title: "Total Views",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.views,
    },
    {
      title: "Saves",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.saveds,
    },
    {
      title: "Likes",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.likes,
    },
    {
      title: "Shares",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.shares,
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Infographics</h1>
          <div>
            <Button
              icon={<UnorderedListOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => setMetricsMode(false)}
              type={!metricsMode ? "primary" : "default"}
            >
              Management
            </Button>
            <Button
              icon={<BarChartOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => setMetricsMode(true)}
              type={metricsMode ? "primary" : "default"}
            >
              Metrics
            </Button>
            <Divider type='vertical' />
            <Link to={"/content-management/infographic/create/"}>
              <Button type='primary' style={{ marginRight: 10 }}>
                New infographic
              </Button>
            </Link>
          </div>
        </Row>
        <div className='site-layout-infographic'>
          <FiltersDrawer
            fields={[
              "companies",
              "isPublic",
              "languages",
              "medicalSpecialties",
              "owners",
              "tags",
              "rooms",
              "status",
              "search",
            ]}
            onChange={handleChangeFilters}
          />

          <div style={{ height: 16 }} />
          <Spin spinning={isLoading} tip='Loading...'>
            <Table
              sticky
              size='small'
              columns={metricsMode ? columnsMetrics : columns}
              dataSource={innerInfographicList}
              pagination={false}
              scroll={{
                x: "max-infographic",
                y: "calc(100vh - 300px)",
              }}
              onChange={handleTableChange}
            />
          </Spin>
          <Pagination
            style={{ marginTop: "10px" }}
            onChange={handlePaginationChange}
            total={count}
            current={page}
            defaultCurrent={1}
            defaultPageSize={100}
            pageSizeOptions={["10", "20", "50", "100"]}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
};
