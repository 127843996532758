import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Row, Table } from "antd";

import { listJournals } from "../services";
import { IJournal } from "../model";
import * as _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";

export const JournalList: React.FC = () => {
  const navigate = useNavigate();
  const [innerJournalList, setInnerJournalList] = useState<IJournal[]>([]);
  const [searchValue, setSearchValue] = useState<string>();

  const fetch = async () => {
    if (!innerJournalList.length) {
      const journalList = await listJournals();
      setInnerJournalList(journalList);
    }
  };

  const handleClick = () => {
    navigate("/reference-data/journal/create/");
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<IJournal> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => {
        const journal = _.find(innerJournalList, { name: text });
        if (journal) {
          return (
            <Link to={`/reference-data/journal/${journal._id}`}>
              {journal.name}
            </Link>
          );
        }
      },
    },
    {
      title: "Impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
    },
    {
      title: "Impact score",
      dataIndex: "impact_score",
      key: "impact_score",
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row justify='space-between' style={{ margin: "20px 0" }}>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Journals</h1>
          <Button type='primary' onClick={handleClick}>
            New journal
          </Button>
        </Row>
        <Input.Search
          placeholder='Search journal'
          allowClear
          onSearch={setSearchValue}
        />
        <div style={{ height: 16 }} />
        <Table
          columns={columns}
          dataSource={innerJournalList
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((v) =>
              v.name.toLowerCase().includes(searchValue?.toLowerCase() ?? "")
            )}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
        />
      </Layout.Content>
    </Layout>
  );
};
