import { IMapping } from ".";
import { SupportedLanguage } from "../model";

export const createCompanyMapping: { [key: string]: IMapping } = {
  language: {
    label: "Language",
    type: "select",
    enum: Object.values(SupportedLanguage),
    required: true,
  },
  name: {
    label: "Name",
    type: "text",
    required: true,
  },
  rank: {
    label: "Rank",
    type: "number",
    min: 1,
    max: 100,
    required: true,
  },
  description: {
    label: "Description",
    type: "textarea",
    required: false,
  },
  website: {
    label: "Website",
    type: "text",
    required: false,
  },
  video_link: {
    label: "Video link",
    type: "text",
    required: false,
  },
  type: {
    label: "Type",
    type: "text",
    required: false,
  },
};
