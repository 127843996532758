import axios, { Method } from "axios";
import qs from "qs";
const apiKey = import.meta.env.VITE_APP_API_KEY;

export const AxiosPromise = async (
  method: Method,
  url: string,
  data?: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any,
  contentType?: string,
  headers?: Record<string, string>
) => {
  const accessToken = localStorage.getItem("juisci-accessToken");
  const refreshToken = localStorage.getItem("juisci-refreshToken");
  const accessTokenExpiration = localStorage.getItem("juisci-expirationTime")
    ? parseFloat(localStorage.getItem("juisci-expirationTime") as string)
    : 0;

  if (
    (!accessToken || !refreshToken || !accessTokenExpiration) &&
    !url.includes("auth/adminauthenticate")
  ) {
    return { data: null };
  }

  if (accessToken && accessTokenExpiration - Date.now() < 1800 * 1000) {
    const refresh = await axios({
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      url: `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
      maxContentLength: 100 * 1024 * 1024,
      maxBodyLength: 100 * 1024 * 1024,
      data: {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
    });
    if (refresh.data) {
      localStorage.setItem("juisci-accessToken", refresh.data.access_token);
      localStorage.setItem("juisci-refreshToken", refresh.data.refresh_token);
      localStorage.setItem(
        "juisci-expirationTime",
        (Date.now() + refresh.data.expires_in * 1000).toString()
      );
    }
    axios.defaults.headers.common.Authorization = `Bearer ${refresh.data.access_token}`;
  } else {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const response = axios({
    paramsSerializer: (params) => {
      return decodeURIComponent(qs.stringify(params, { arrayFormat: "comma" }));
    },
    data,
    headers: {
      ...headers,
      "Content-Type": contentType ? contentType : "application/json",
      "ngrok-skip-browser-warning": "any",
    },
    method,
    params,
    url,
  });

  // response.catch((response) => {
  //   if (response.response.status === 401) {
  //     localStorage.removeItem("juisci-accessToken");
  //     localStorage.removeItem("juisci-refreshToken");
  //     localStorage.removeItem("juisci-expirationTime");
  //     window.location.href = "/";
  //   }
  // });

  return response;
};
