import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, message, Modal, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { IArticle } from "~/model";
import {
  deleteArticleFromRoom,
  postArticleToRoom,
  searchArticlesV2,
} from "~/services";

const RoomEditionArticles = (props: { articles: IArticle[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [innerArticles, setInnerArticles] = useState<IArticle[]>(
    props.articles
  );
  const [searchResults, setSearchResults] = useState<IArticle[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const searchArticles = async () => {
      if (!searchValue.length) return setSearchResults([]);

      const res = await searchArticlesV2({ search: searchValue });

      if (res.hits.hits) {
        setSearchResults(
          res.hits.hits
            .map((article: { _source: { core: IArticle; id: string } }) => ({
              ...article._source.core,
              id: article._source.id,
            }))
            .filter((article: IArticle) => {
              return !innerArticles.find(
                (innerArticle) => innerArticle._id === article.id
              );
            })
        );
      }
    };

    searchArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleAddArticle = async (article: IArticle) => {
    try {
      if (!params.id) throw new Error("No room id provided");

      await postArticleToRoom(params.id, article.id);

      setInnerArticles([article, ...innerArticles]);
      message.success("Article added to the room successfully!");
    } catch (error) {
      message.error("An error occured while adding the article to the room");
      throw error;
    }
  };

  const handleDeleteArticle = async (articleId: string) => {
    const callback = async () => {
      try {
        if (!params.id) throw new Error("No room id provided");

        await deleteArticleFromRoom(params.id, articleId);

        setInnerArticles(
          innerArticles.filter((article) => article._id !== articleId)
        );
        message.success("Article has been successfully removed from the room!");
      } catch (error) {
        message.error(
          "An error occured while removing the article from the room"
        );
        throw error;
      }
    };

    Modal.confirm({
      title: "Are you sure you want to remove this article from the room?",
      content: "This action is irreversible",
      onOk: callback,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns: ColumnsType<IArticle> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: () => (
        <Input.Search
          placeholder='Search articles'
          allowClear
          onSearch={(val) => setSearchFilter(val)}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#ffc408" : undefined }} />
      ),
      render: (_text: string, article: IArticle) => (
        <Link to={`/content-management/article/${article.slug}`}>
          {article.title}
        </Link>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 81,
      render: (_text: string, article: IArticle) => (
        <Button
          danger
          shape={"circle"}
          icon={<CloseOutlined />}
          onClick={() => handleDeleteArticle(article.id)}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          bordered
          columns={columns}
          dataSource={innerArticles
            .map((article) => ({
              ...article,
              key: article._id,
            }))
            .filter((article) => {
              if (!searchFilter) return true;
              return article.title
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            })}
        />
      </Col>
      <Col span={8}>
        <Input.Search
          placeholder='Add articles to this room'
          allowClear
          onSearch={(val) => setSearchValue(val)}
        />
        <Divider />
        {searchResults.length ? (
          <Flex
            gap={8}
            flexDirection='column'
            style={{ height: 800, overflowY: "scroll", overflowX: "hidden" }}
          >
            {searchResults.map((article) => (
              <Card key={`${article.slug}-search`}>
                <p>{article.title}</p>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddArticle(article)}
                >
                  Add this Article
                </Button>
              </Card>
            ))}
          </Flex>
        ) : null}
      </Col>
    </Row>
  );
};

export default RoomEditionArticles;
