import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Form,
  notification,
  Layout,
  Row,
  Spin,
  Col,
  Divider,
  Input,
  Select,
  Switch,
} from "antd";

import { red } from "@ant-design/colors";
import { LeftOutlined } from "@ant-design/icons";

import {
  getOrganisation,
  createOrganisation,
  deleteOrganisation,
  updateOrganisation,
  uploadOrganisationImage,
  getProfessionList,
  updateOrganisationSettings,
} from "../services";
import { IImage, IOrganisation, IProfession, ITag } from "../model";
import { createOrganisationMapping } from "../config/createOrganisationMapping";
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "../config/dropzone";
import { FormMapping } from "./shared/FormMapping";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingLayout from "./shared/LoadingLayout";
import { Flex, Spacer } from "./shared/global";
import FormItem from "antd/es/form/FormItem";
import { languageOptions, searchFilterOptions } from "~/utils/helpers";
import { GlobalContext } from "~/context/global.context";
import { useWatch } from "antd/lib/form/Form";

export const OrganisationEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const organisationId = params.id;
  const { tagList } = useContext(GlobalContext);
  const [organisationSaving, setOrganisationSaving] = useState<boolean>(false);
  const [innerOrganisation, setInnerOrganisation] = useState<IOrganisation>();
  const [professions, setProfessions] = useState<IProfession[] | null>(null);
  const [innerImage, setInnerImage] = useState<IImage>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMainSpecialty, setSelectedMainSpecialty] = useState<string>();
  const [form] = Form.useForm();
  const selectedSpecialties = useWatch(
    ["organisationSettings", "mainSpecialty"],
    form
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchOrganisation = async () => {
    if (organisationId && !innerOrganisation) {
      const organisation = await getOrganisation(organisationId);
      setInnerOrganisation(organisation);
      setSelectedMainSpecialty(
        organisation?.organisationSettings?.mainSpecialty as unknown as string
      );
      if (organisation.logo) {
        setInnerImage(organisation.logo);
      }
    }
  };

  useEffect(() => {
    fetchOrganisation();
    fetchProfessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturn = () => {
    navigate(-1);
  };

  const openNotificationSuccess = (entity: string) => {
    notification["success"]({
      message: `${entity} saved`,
      description: `The ${entity.toLowerCase()} was succesfully saved.`,
    });
  };

  const handleImageUpload = async () => {
    setIsLoading(true);
    if (organisationId && acceptedFiles.length && innerOrganisation?._id) {
      const organisation = await uploadOrganisationImage(
        innerOrganisation?._id,
        acceptedFiles[0]
      );
      if (organisation) {
        setInnerImage(organisation.logo);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleEdition = async (
    values: IOrganisation & {
      organisationSettings: {
        backgroundColor: string;
        professions: string[];
        mainSpecialty: string;
        medicalSpecialties: string[];
        discoveryFilter: boolean;
        primaryColor?: string;
        secondaryColor?: string;
        subtitle?: string;
      };
    }
  ) => {
    const initialSettings = innerOrganisation?.organisationSettings;

    const { organisationSettings, ...otherValues } = values;

    const settings = {
      ...organisationSettings,
      mainSpecialty:
        !!initialSettings?.mainSpecialty && !organisationSettings.mainSpecialty
          ? null
          : organisationSettings.mainSpecialty,
    };

    setOrganisationSaving(true);
    if (organisationId && innerOrganisation?._id) {
      const updatedOrganisation = await updateOrganisation(
        innerOrganisation?._id,
        otherValues
      );

      const { organisationSettings } = await updateOrganisationSettings(
        innerOrganisation?._id,
        settings
      );

      const newSettings = {
        ...organisationSettings,
        mainSpecialty: organisationSettings?.mainSpecialty
          ?._id as unknown as ITag,
        medicalSpecialties: organisationSettings?.medicalSpecialties?.map(
          (spe) => spe._id as unknown as ITag
        ),
        tags: organisationSettings?.tags?.map(
          (spe) => spe._id as unknown as ITag
        ),
      };

      setInnerOrganisation({
        ...updatedOrganisation,
        organisationSettings: newSettings,
      });
    } else {
      const createdOrganisation = await createOrganisation(values);
      if (createdOrganisation) {
        navigate(`/sensitive-data/organisations/${createdOrganisation._id}`);
        setInnerOrganisation(createdOrganisation);
        setOrganisationSaving(false);
      }
    }
    setOrganisationSaving(false);
    openNotificationSuccess("Organisation");
  };

  const handleDeletion = async () => {
    if (organisationId && innerOrganisation) {
      await deleteOrganisation(innerOrganisation._id);
      notification["success"]({
        message: "Organisation deleted",
        description: "The organisation was succesfully deleted.",
      });
      navigate("/sensitive-data/organisations/");
    }
  };

  const fetchProfessions = async () => {
    const { docs: professionList } = await getProfessionList({ limit: 100 });

    setProfessions(professionList);
  };

  if ((!innerOrganisation || !professions) && organisationId)
    return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row>
          <div>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
          </div>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            {organisationId ? "Edit organisation" : "Create organisation"}
          </h1>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={organisationSaving} tip='Organisation saving...'>
            <Form
              form={form}
              name='organisation_edition'
              layout='vertical'
              initialValues={{
                ...innerOrganisation,
              }}
              onFinish={handleEdition}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                }}
                justify='space-between'
                gutter={16}
              >
                <Divider orientation='left'>Informations</Divider>

                <Col span={12}>
                  {Object.keys(createOrganisationMapping).map((key: string) => (
                    <FormMapping
                      key={key}
                      formMapping={createOrganisationMapping}
                      id={key}
                    />
                  ))}
                </Col>

                {organisationId ? (
                  <Col span={12}>
                    <Spin spinning={isLoading} tip='Image update...'>
                      <div>
                        {innerImage ? (
                          <img
                            key={innerImage.url}
                            style={{
                              maxHeight: "72px",
                              padding: "16px",
                              borderRadius: "5px",
                            }}
                            src={innerImage.url}
                            alt={innerImage.url}
                          />
                        ) : null}
                        <Flex>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              maxFiles: 1,
                            })}
                          >
                            <input {...getInputProps({ style })} />
                          </div>
                          <Button
                            type='primary'
                            style={{ marginLeft: "20px" }}
                            onClick={handleImageUpload}
                          >
                            Upload image
                          </Button>
                        </Flex>
                        <Divider />
                      </div>
                    </Spin>
                  </Col>
                ) : null}
              </Row>
              <Row gutter={48}>
                <Divider orientation='left'>Settings</Divider>
                <Spacer />
                <Col span={12}>
                  <FormItem
                    name={["organisationSettings", "subtitle"]}
                    label='Subtitle'
                  >
                    <Input placeholder='Subtitle' />
                  </FormItem>
                  <FormItem
                    name={["organisationSettings", "professions"]}
                    label='Professions'
                  >
                    <Select
                      showSearch
                      allowClear
                      mode='multiple'
                      placeholder={`Pre-selected professions for ${innerOrganisation?.name}`}
                      style={{ width: "100%" }}
                      filterOption={searchFilterOptions}
                      options={professions?.map((prof) => ({
                        label: prof.translations.en,
                        value: prof._id,
                      }))}
                    />
                  </FormItem>
                  <FormItem
                    name={["organisationSettings", "mainSpecialty"]}
                    label='Main specialty'
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={`Main specialty for ${innerOrganisation?.name}`}
                      style={{ width: "100%" }}
                      filterOption={searchFilterOptions}
                      onChange={(value) => setSelectedMainSpecialty(value)}
                      options={tagList
                        .filter((el) => !el.parent)
                        ?.map((spe) => ({
                          label: spe.translations.en,
                          value: spe._id,
                        }))}
                    />
                  </FormItem>
                  <FormItem
                    name={["organisationSettings", "medicalSpecialties"]}
                    label='Other medical specialties'
                  >
                    <Select
                      showSearch
                      allowClear
                      mode='multiple'
                      placeholder={`Pre-selected medical specialties for ${innerOrganisation?.name}`}
                      style={{ width: "100%" }}
                      filterOption={searchFilterOptions}
                      options={tagList
                        .filter((el) => !el.parent)
                        .filter((el) => el._id !== selectedMainSpecialty)
                        .map((spe) => ({
                          label: spe.translations.en,
                          value: spe._id,
                        }))}
                    />
                  </FormItem>
                  <FormItem
                    name={["organisationSettings", "tags"]}
                    label='Tags'
                  >
                    <Select
                      showSearch
                      allowClear
                      mode='multiple'
                      placeholder={`Pre-selected tags for ${innerOrganisation?.name}`}
                      style={{ width: "100%" }}
                      filterOption={searchFilterOptions}
                      options={tagList
                        .filter((el) => !!el.parent)
                        .filter(
                          (el) =>
                            el.parent?._id == selectedMainSpecialty ||
                            selectedSpecialties?.includes(el.parent?._id)
                        )
                        .map((spe) => ({
                          label: `${spe.translations.en} (${spe.parent?.translations.en})`,
                          value: spe._id,
                        }))}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["organisationSettings", "allowedLanguages"]}
                    label='Allowed Languages'
                  >
                    <Select
                      options={languageOptions}
                      mode='multiple'
                      placeholder='Select languages'
                    />
                  </Form.Item>
                  <Flex gap={8}>
                    <FormItem
                      tooltip='Background color in-app for DISCOVERY PAGE.'
                      name={["organisationSettings", "primaryColor"]}
                      label='Primary color'
                    >
                      <Input placeholder='Primary color in-app' />
                    </FormItem>
                    <FormItem
                      tooltip='Background color in-app for CONGRESS PAGE.'
                      name={["organisationSettings", "secondaryColor"]}
                      label='Secondary color'
                    >
                      <Input placeholder='Secondary color in-app' />
                    </FormItem>
                  </Flex>
                  <FormItem
                    name={["organisationSettings", "discoveryFilter"]}
                    label={`Enable Discovery filters for ${innerOrganisation?.name}?`}
                  >
                    <Switch
                      defaultChecked={
                        innerOrganisation?.organisationSettings?.discoveryFilter
                      }
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='organisation-form-button'
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {organisationId
                      ? "Save organisation"
                      : "Create organisation"}
                  </Button>
                  {organisationId ? (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                    >
                      Delete organisation
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
