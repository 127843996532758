/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Card,
  Form,
  Input,
  notification,
  Layout,
  Row,
  Select,
  Spin,
  Tabs,
  Col,
  DatePicker,
  Tag,
} from "antd";

import { red, grey } from "@ant-design/colors";
import { LeftOutlined } from "@ant-design/icons";

import {
  getCompany,
  createCompany,
  deleteCompany,
  updateCompany,
  uploadCompanyImage,
  removeCompanyInfo,
  addCompanyInfo,
  publishCompany,
  unpublishCompany,
} from "../services";
import {
  ICompany,
  ICompanyInfo,
  IImage,
  PermissionEnum,
  PermissionLabel,
  SupportedLanguage,
} from "../model";
import { ICreateCompanyDto } from "../model/dto/company-dto";
import { createCompanyMapping } from "../config/createCompanyMapping";
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "../config/dropzone";
import { FormMapping } from "./shared/FormMapping";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { checkPermission } from "~/utils/helpers";
import { GlobalContext } from "~/context/global.context";

const infoType = {
  [SupportedLanguage.EN]: [
    "Congress",
    "News",
    "Medical information",
    "Event",
    "Webinar",
  ],
  [SupportedLanguage.FR]: [
    "Congrès",
    "Actualité",
    "Info médicale",
    "Évènement",
    "Webinaire",
  ],
  [SupportedLanguage.IT]: [
    "Congresso",
    "Notizie",
    "Informazioni mediche",
    "Evento",
    "Webinar",
  ],
  [SupportedLanguage.ES]: [
    "Congreso",
    "Noticias",
    "Información médica",
    "Evento",
    "Webinario",
  ],
};

export const CompanyEdition: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const companyId = params.id;
  const search = new URLSearchParams(location.search);
  const { user } = useContext(GlobalContext);
  const [innerCompany, setInnerCompany] = useState<ICompany>();
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );
  const [innerImages, setInnerImages] = useState<string[]>([]);
  const [companySaving, setCompanySaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchCompany = async () => {
    if (companyId && !innerCompany) {
      const company = await getCompany(companyId);
      setInnerCompany(company);
      setLanguage(company.language);
      if (company.images.length) {
        setInnerImages(company.images.map((image: IImage) => image.url));
      }
    }
  };

  useEffect(() => {
    fetchCompany();
  });

  const handleReturn = () => {
    navigate(`/reference-data/company/?language=${language}`);
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Company saved",
      description: "The company was succesfully saved.",
    });
  };

  const handleEdition = async (values: ICompany) => {
    setCompanySaving(true);
    if (companyId) {
      const content = (values as any)["info.content"];
      const link = (values as any)["info.link"];
      const type = (values as any)["info.type"];
      const creation_date = (values as any)["info.creation_date"];
      if (Object.keys(values).includes("info.content") && content && link) {
        const updatedCompany = await addCompanyInfo(companyId, {
          content,
          creation_date,
          link,
          type,
          cta: (values as any)["info.cta"],
          event_date: (values as any)["info.event_date"],
        });
        if (updatedCompany) {
          setInnerCompany(updatedCompany);
          form.resetFields();
          form.setFieldsValue(updatedCompany);
          setCompanySaving(false);
          openNotificationSuccess();
          return;
        }
      }
      const updatedCompany = await updateCompany(companyId, values);
      if (acceptedFiles.length) {
        const company = await uploadCompanyImage(companyId, acceptedFiles[0]);
        if (company.images.length) {
          setInnerImages(company.images.map((image: IImage) => image.url));
        }
      }
      setInnerCompany(updatedCompany);
      setLanguage(updatedCompany.language);
    } else {
      const createdCompany = await createCompany(values);
      if (createdCompany) {
        navigate(`/reference-data/company/${createdCompany._id}`);
      }
    }
    setCompanySaving(false);
    openNotificationSuccess();
  };

  // const handleInfoEdition = async (_id: string) => {
  //   const currentInfo = _.find(innerCompany?.infos, {
  //     _id,
  //   });

  //   if (currentInfo) {
  //     form.setFieldsValue({
  //       ...innerCompany,
  //       "info.content": currentInfo?.content,
  //       "info.link": currentInfo?.link,
  //       "info.type": currentInfo?.type,
  //       "info.creation_date": moment(currentInfo?.creation_date),
  //       "info.cta": currentInfo?.cta,
  //       "info.event_date": moment(currentInfo?.event_date),
  //     });
  //   }
  // };

  const handleInfoDeletion = async (infoId: string) => {
    if (companyId) {
      setCompanySaving(true);
      const newCompany = await removeCompanyInfo(companyId, infoId);
      if (newCompany) {
        setInnerCompany(newCompany);
      }
      setCompanySaving(false);
      openNotificationSuccess();
    }
  };

  const handleDeletion = async () => {
    if (companyId) {
      const deletedCompany = await deleteCompany(companyId);
      if (deletedCompany) {
        navigate("/reference-data/company/");
      }
    }
  };

  const togglePublish = async () => {
    if (innerCompany) {
      const updatedCompany =
        innerCompany?.meta?.status === "draft"
          ? await publishCompany(innerCompany._id)
          : await unpublishCompany(innerCompany._id);
      setInnerCompany(updatedCompany);
      setLanguage(updatedCompany.language);
    }
  };

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row justify='space-between' align='middle'>
          <div>
            <Row align='middle'>
              <div>
                <Button
                  onClick={handleReturn}
                  shape='circle'
                  style={{ marginRight: 20 }}
                >
                  <LeftOutlined />
                </Button>
              </div>
              <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
                {companyId ? "Edit company" : "Create company"}
              </h1>
            </Row>
          </div>
          <div>
            {companyId ? (
              <Tag
                style={{ padding: "5px 7px", fontSize: "14px" }}
                color={
                  innerCompany?.meta?.status === "draft" ? "warning" : "success"
                }
              >
                {innerCompany?.meta?.status?.toUpperCase()}
              </Tag>
            ) : null}
            <Button style={{ marginRight: "10px" }} onClick={togglePublish}>
              {innerCompany?.meta?.status === "draft"
                ? "Publish "
                : "Unpublish"}
            </Button>
          </div>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={companySaving} tip='Company saving...'>
            <Form
              form={form}
              name='company_edition'
              layout='vertical'
              fields={
                innerCompany
                  ? Object.keys(innerCompany).map((key: string) => ({
                      name: [key],
                      value: innerCompany[key as keyof ICreateCompanyDto],
                    }))
                  : [{ name: "language", value: language }]
              }
              onFinish={handleEdition}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                  borderBottom: "1px solid #d9d9d9",
                }}
                justify='space-between'
              >
                <Tabs defaultActiveKey='1'>
                  <TabPane tab='Details' key='1'>
                    {Object.keys(createCompanyMapping).map((key: string) => (
                      <FormMapping
                        key={key}
                        formMapping={createCompanyMapping}
                        id={key}
                      />
                    ))}
                    {companyId ? (
                      <Form.Item key='image' label='Image' name='image'>
                        <div style={{ display: "flex" }}>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              maxFiles: 1,
                            })}
                          >
                            <input {...getInputProps({ style })} />
                          </div>
                          {innerImages
                            ? innerImages.map((image: string) => (
                                <img
                                  key={image}
                                  style={{
                                    maxHeight: "72px",
                                    marginLeft: "10px",
                                  }}
                                  src={image}
                                  alt={image}
                                />
                              ))
                            : null}
                        </div>
                      </Form.Item>
                    ) : null}
                  </TabPane>
                  <TabPane tab='Infos' key='2'>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0",
                      }}
                    >
                      <Col span={11}>
                        <h3 style={{ marginBottom: "20px" }}>Current infos</h3>
                        {innerCompany?.infos?.map((info: ICompanyInfo) => (
                          <Card
                            key={info.link}
                            style={{ marginBottom: "20px" }}
                            title={info.type}
                            extra={
                              <a href={info.link}>
                                {info.cta ? info.cta : "Link"}
                              </a>
                            }
                          >
                            {info?.creation_date || info.event_date ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "20px",
                                }}
                              >
                                {info?.creation_date ? (
                                  <span style={{ color: grey.primary }}>
                                    Creation date:{" "}
                                    {new Date(
                                      info.creation_date
                                    ).toLocaleDateString()}
                                  </span>
                                ) : null}
                                {info?.event_date ? (
                                  <span style={{ color: grey.primary }}>
                                    Event date:{" "}
                                    {new Date(
                                      info.event_date
                                    ).toLocaleDateString()}
                                  </span>
                                ) : null}
                              </div>
                            ) : null}
                            <p>{info.content}</p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              {/* <Button
                                type='primary'
                                onClick={() => handleInfoEdition(info.link)}
                              >
                                Edit
                              </Button> */}
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: red.primary,
                                  float: "right",
                                  marginLeft: "20px",
                                }}
                                onClick={() => handleInfoDeletion(info._id)}
                              >
                                Remove
                              </Button>
                            </div>
                          </Card>
                        ))}
                      </Col>
                      <Col span={11}>
                        <h3 style={{ marginBottom: "20px" }}>New info</h3>
                        <Form.Item
                          required
                          key='info.type'
                          label='Type'
                          name='info.type'
                        >
                          <Select>
                            {/* {infoType[language || SupportedLanguage.EN]?.map( */}
                            {infoType[SupportedLanguage.EN]?.map(
                              (option: string) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          required
                          key='info.content'
                          label='Content'
                          name='info.content'
                        >
                          <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                          required
                          key='info.creation_date'
                          label='Creation date'
                          name='info.creation_date'
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          key='info.event_date'
                          label='Event date'
                          name='info.event_date'
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          required
                          key='info.link'
                          label='Link'
                          name='info.link'
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item key='info.cta' label='CTA' name='info.cta'>
                          <Input />
                        </Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          className='info-form-button'
                          style={{ float: "right" }}
                        >
                          Add new info
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Row>
              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='company-form-button'
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {companyId ? "Save company" : "Create company"}
                  </Button>
                  {companyId ? (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                      disabled={
                        (user || false) &&
                        !checkPermission(
                          user,
                          PermissionLabel.Referential,
                          PermissionEnum.Delete
                        )
                      }
                    >
                      Delete company
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
