import { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Card, Divider, Layout, Row, Table, Tag, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertNectarQuestion,
  deleteNectarQuestion,
  getNectarQuestionById,
} from "../services";
import { IArticle, INectarQuestion } from "../model";
import LoadingLayout from "./shared/LoadingLayout";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

const NectarQuestionPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [nectarQuestion, setNectarQuestion] = useState<INectarQuestion | null>(
    null
  );

  const fetchNectarQuestion = async (nectarQuestionId: string) => {
    const res = await getNectarQuestionById(nectarQuestionId);
    setNectarQuestion(res);
  };

  const parseMarkdownLinks = (text: string) => {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (index % 3 === 1) {
        return (
          <Link
            key={`markdown-link-${index}`}
            to={`/content-management/article/${
              parts[index + 1].split("/").pop() || ""
            }`}
          >
            {part}
          </Link>
        );
      } else if (index % 3 === 0) {
        return part;
      }

      return null;
    });
  };

  const handleConvertQuestion = async () => {
    if (!params?.id) return;

    const nectar = await convertNectarQuestion(params.id);
    message.success({
      content: "Question converted to nectar",
    });
    navigate(`/content-management/nectar/${nectar._id}`, { replace: true });
  };

  const handleDeleteQuestion = async () => {
    if (!params?.id) return;

    await deleteNectarQuestion(params.id);
    message.success({
      content: "Question deleted",
    });

    navigate("/content-management/nectar");
  };

  const sourcesColumns: ColumnsType<IArticle> = [
    {
      title: "Status",
      dataIndex: ["meta", "status"],
      key: "status",
      render: (status: string) => {
        if (status === "draft") {
          return <Tag color='orange'>Draft</Tag>;
        } else if (status === "published") {
          return <Tag color='green'>Published</Tag>;
        }
        return null;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string, article) => (
        <Link to={`/content-management/article/${article.slug}`}>{title}</Link>
      ),
    },
  ];

  useEffect(() => {
    if (!params?.id) return;

    fetchNectarQuestion(params.id);
  }, [params]);

  if (!nectarQuestion) return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
          minHeight: "calc(100vh - 66px)",
        }}
      >
        <Button
          style={{ padding: 0 }}
          type='link'
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1
            onClick={() => console.log(nectarQuestion)}
            style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}
          >
            Question
          </h1>

          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              onClick={handleDeleteQuestion}
            >
              Delete question
            </Button>
            <Divider type='vertical' />
            <Button
              type='primary'
              icon={<SyncOutlined />}
              onClick={handleConvertQuestion}
            >
              Convert to Nectar
            </Button>
          </div>
        </Row>
        <Card title={nectarQuestion.question}>
          <p style={{ fontSize: 15 }}>
            {parseMarkdownLinks(nectarQuestion.answer)}
          </p>
          <small>
            {nectarQuestion.language === "en" ? "English" : "French"} -{" "}
            {moment(nectarQuestion.createdAt).format("DD/MM/YYYY")}
          </small>
        </Card>
        <div style={{ height: 16 }} />

        <Card title='Sources'>
          <Table
            columns={sourcesColumns}
            dataSource={nectarQuestion.sources?.map((source) => ({
              ...source,
              key: source._id,
            }))}
          />
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default NectarQuestionPage;
