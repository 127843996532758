import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Layout,
  Modal,
  Row,
  Tag,
  Upload,
  message,
  notification,
} from "antd";

import {
  createBatchedParsing,
  deleteParsing,
  getParsing,
  parsePdf,
  postRetryParsing,
} from "../services";
import { IParsing } from "../model";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  LeftOutlined,
  PlusCircleFilled,
  SnippetsOutlined,
  SyncOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Spacer } from "./shared/global";
import LoadingLayout from "./shared/LoadingLayout";
import axios, { AxiosError } from "axios";

export const ParsingEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [innerParsing, setInnerParsing] = useState<IParsing>();
  const [innerBatchName, setInnerBatchName] = useState<string>("");
  const [innerFiles, setInnerFiles] = useState<File[]>([]);
  const [isParsing, setisParsing] = useState<boolean>(false);
  const parsingId = params.id;
  const parsingAPI = import.meta.env.VITE_APP_PARSING_API;

  const fetch = async () => {
    if (parsingId && !innerParsing) {
      const parsing = await getParsing(parsingId);
      setInnerParsing(parsing);
    }
  };

  const handleParsing = async () => {
    setisParsing(true);

    if (!innerFiles.length) return;

    if (innerFiles.length > 1) {
      if (!innerBatchName.length)
        return message.error("Please provide a batch name");

      await createBatchedParsing(innerFiles, innerBatchName);
      setisParsing(false);
    } else {
      const formData = new FormData();
      formData.append("file", innerFiles[0]);

      await parsePdf(formData);
      setisParsing(false);
    }

    navigate(`/content-sources/parsing/`);
    notification.success({
      message: "Parsing created",
      description: "Parsing was successfully created",
    });
  };

  const handleDeletion = async () => {
    if (!parsingId) return message.error("Parsing ID not found");

    Modal.confirm({
      title: "Are you sure you want to delete this parsing?",
      content: "This action cannot be undone",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteParsing(parsingId).then((deletedParsing) => {
          if (deletedParsing) {
            navigate("/content-sources/parsing/");
          }
        });
      },
    });
  };

  const handleRetryParsing = async () => {
    if (!innerParsing?.taskId)
      return message.error("Associated Task ID not found");

    try {
      const res = await postRetryParsing(innerParsing?.taskId);
      navigate(-1);
      if (res)
        notification.success({
          message: "Parsing retry",
          description: "Parsing was successfully retried",
        });
      else throw new AxiosError("Parsing retry failed");
    } catch (error) {
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred while retrying parsing",
          description: error?.response?.data.message || "Unknown error",
        });
    }
  };

  useEffect(() => {
    fetch();
  });

  if (!innerParsing && params.id) return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px", height: "calc(100vh - 66px)" }}>
        <Button
          type='link'
          icon={<LeftOutlined />}
          onClick={() => navigate("/content-sources/parsing")}
          style={{ padding: 0 }}
        >
          Back
        </Button>
        <Spacer />
        <Flex justify='space-between' align='center'>
          <div>
            <h1 style={{ fontWeight: 800, fontSize: "30px" }}>PDF Parser</h1>
            <h3 style={{ marginTop: 0 }}>
              {innerParsing?.article?.title ||
                innerParsing?.pdfName ||
                innerParsing?.doi}
            </h3>
          </div>
          {params.id ? (
            <Flex align='center' justify='end' gap={8}>
              <Tag
                style={{
                  padding: "6px 16px",
                  fontSize: 12,
                  margin: 0,
                }}
              >
                <Flex align='center' gap={8}>
                  <span>{innerParsing?.batchName ? "🗃️" : "📄"}</span>
                  {innerParsing?.batchName ? "BATCH" : "SINGLE"}
                </Flex>
              </Tag>
              <Tag
                style={{ padding: "6px 16px", fontWeight: 600, margin: 0 }}
                icon={
                  innerParsing?.meta?.status === "success" ? (
                    <CheckOutlined />
                  ) : innerParsing?.meta?.status === "failure" ? (
                    <CloseOutlined />
                  ) : innerParsing?.meta?.status === "duplicate" ? (
                    <WarningOutlined />
                  ) : (
                    <SyncOutlined spin />
                  )
                }
                color={
                  innerParsing?.meta?.status === "success"
                    ? "success"
                    : innerParsing?.meta?.status === "failure"
                    ? "error"
                    : innerParsing?.meta?.status === "duplicate"
                    ? "blue"
                    : "warning"
                }
              >
                {innerParsing?.meta?.status.toLocaleUpperCase()}
              </Tag>
              <Divider type='vertical' />
              {innerParsing?.meta?.status === "failure" && (
                <Button icon={<SyncOutlined />} onClick={handleRetryParsing}>
                  Retry parsing
                </Button>
              )}
              <Button
                icon={<DeleteOutlined />}
                type='primary'
                danger
                onClick={handleDeletion}
              >
                Delete parsing
              </Button>
            </Flex>
          ) : null}
        </Flex>
        <Spacer />
        <Row gutter={8}>
          <Col span={params.id ? 12 : 24}>
            <Card
              style={{
                minHeight: "100%",
                opacity: !["success", "duplicate"].includes(
                  innerParsing?.meta?.status || ""
                )
                  ? 1
                  : 0.5,
                pointerEvents: !["success", "duplicate"].includes(
                  innerParsing?.meta?.status || ""
                )
                  ? "all"
                  : "none",
              }}
              title={
                <h3>
                  <UploadOutlined style={{ marginRight: 8 }} />{" "}
                  {"Upload documents"}
                </h3>
              }
            >
              <Flex flexDirection='column' gap={16}>
                {(!params.id || innerParsing?.batchName) && (
                  <Input
                    disabled={innerFiles.length < 2}
                    defaultValue={innerParsing?.batchName || innerBatchName}
                    placeholder='Batch name'
                    onChange={(e) => setInnerBatchName(e.target.value)}
                  />
                )}
                {innerFiles.length > 1 && <Divider style={{ margin: 4 }} />}
                <div className='full-width-upload-btn'>
                  <Upload
                    multiple={!params.id}
                    accept='.pdf'
                    customRequest={(payload) => {
                      if (payload.onSuccess) payload.onSuccess("ok");
                    }}
                    onChange={({ file, fileList }) => {
                      if (file.status === "error") {
                        message.error(`${file.name} file upload failed.`);
                      }

                      setInnerFiles(
                        fileList.map((file) => file.originFileObj as File)
                      );
                    }}
                  >
                    <Button
                      disabled={isParsing}
                      block
                      icon={<UploadOutlined />}
                    >
                      Upload Files
                    </Button>
                  </Upload>
                </div>
                {innerFiles.length > 0 && <Divider style={{ margin: 4 }} />}
                {innerFiles.length > 0 && (
                  <Button
                    block
                    type='primary'
                    onClick={handleParsing}
                    disabled={innerFiles.length === 0}
                    loading={isParsing}
                    icon={<PlusCircleFilled />}
                    style={{ fontWeight: 600 }}
                  >
                    {"Create Parsing"}
                  </Button>
                )}
              </Flex>
            </Card>
          </Col>

          <Col span={params.id ? 12 : 0}>
            <Card
              style={{
                minHeight: "100%",
                opacity: parsingId ? 1 : 0.5,
                pointerEvents: parsingId ? "all" : "none",
              }}
              title={
                <h3>
                  <FileOutlined style={{ marginRight: 8 }} /> {"Results"}
                </h3>
              }
            >
              {innerParsing?.result && innerParsing?.article ? (
                <Flex gap={8}>
                  <Button
                    disabled={!innerParsing.article.slug}
                    icon={<SnippetsOutlined />}
                    href={`/content-management/article/${innerParsing.article.slug}`}
                    block
                  >
                    View Article
                  </Button>

                  <Button
                    download
                    disabled={!innerParsing?.result?.summarized?.doi}
                    href={`${parsingAPI}/api/v1/download_pdf?doi=${innerParsing?.result?.summarized?.doi}`}
                    target='_blank'
                    block
                  >
                    Download PDF <DownloadOutlined />
                  </Button>
                </Flex>
              ) : innerParsing?.meta.status === "success" &&
                !innerParsing?.result ? (
                <Alert
                  showIcon
                  message='Parser returned no result'
                  type='error'
                />
              ) : innerParsing?.meta.status === "success" &&
                Object.keys(innerParsing.result).includes("detail") ? (
                <Alert
                  showIcon
                  message='Parser returned an error'
                  type='error'
                />
              ) : (
                <Alert showIcon message='No result for PDF' />
              )}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};
