import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Tabs,
  Tag,
} from "antd";

import { red } from "@ant-design/colors";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  addContentToContent,
  addInfographicArticle,
  createInfographic,
  deleteInfographic,
  getInfographic,
  listCongress,
  publishInfographic,
  removeContentFromContent,
  removeInfographicArticle,
  searchArticlesV2,
  searchVideos,
  unpublishInfographic,
  updateInfographic,
  uploadInfographiThumbnail,
  uploadInfographicImage,
} from "../services";
import {
  IInfographic,
  ITag,
  IArticle,
  ICongress,
  IQuery,
  SupportedLanguage,
  SupportedPublisher,
  IVideo,
  IContent,
  PermissionLabel,
  PermissionEnum,
  ContentFormatsEnum,
} from "../model";

import { GlobalContext } from "../context/global.context";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageUploader from "./shared/ImageUploader";
import { Spacer } from "./shared/global";
import {
  checkPermission,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { capitalize } from "lodash";
import slugify from "slugify";
import moment from "moment";
import LoadingLayout from "./shared/LoadingLayout";
import RoomsAssociator from "./shared/RoomsAssociator";
import { FiltersDrawerValuesModel } from "./shared/FiltersDrawer";

const { confirm } = Modal;

type TabsType = "details" | "rooms" | "articles" | "videos";

export const InfographicEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const infographicId = params.id;
  const { companyList, journalList, tagList, user } = useContext(GlobalContext);
  const search = new URLSearchParams(location.search);
  const [innerInfographic, setInnerInfographic] = useState<IInfographic>();

  const [infographicImageFile, setInfographicImageFile] = useState<{
    image: File | null;
    thumbnail: File | null;
  }>({
    image: null,
    thumbnail: null,
  });
  const [infographicSaving, setInfographicSaving] = useState<boolean>(false);
  const [language, setLanguage] = useState<SupportedLanguage>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );
  const [selectedTab, setSelectedTab] = useState<TabsType>("details");
  const [congressList, setCongressList] = useState<ICongress[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [searchArticleList, setSearchArticleList] = useState<IArticle[]>([]);
  const [searchVideoList, setSearchVideoList] = useState<IVideo[]>([]);
  const [selectedMedicalSpecialties, setSelectedMedicalSpecialties] = useState<
    string[]
  >([]);
  const [selectedKey, setSelectedKey] = useState<SupportedPublisher | null>(
    null
  );

  const [form] = Form.useForm<IInfographic>();
  const languageField = Form.useWatch("language", form);
  const { TabPane } = Tabs;

  const fetch = async () => {
    if (!congressList.length) {
      const query: IQuery = {
        limit: 20,
        offset: 0,
        language,
      };
      const congressList = await listCongress(query);
      setCongressList(congressList.docs);
    }

    if (infographicId && !innerInfographic) {
      const infographic = await getInfographic(infographicId);

      if (infographic) {
        setLanguage(infographic.language);
        setSelectedKey(infographic.publisher);
        setSelectedMedicalSpecialties(
          infographic.medical_specialties.map((tag) => (tag as ITag)._id)
        );
        setInnerInfographic(infographic);
      }
    }
  };

  const handleReturn = () => {
    navigate(`/content-management/infographic/?language=${language}`);
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Infographic saved",
      description: "The infographic was succesfully saved.",
    });
  };

  const handleImageUpload = async (file: File) => {
    if (innerInfographic?._id) {
      setInfographicSaving(true);
      try {
        const infographic = await uploadInfographicImage(
          innerInfographic._id,
          file
        );
        if (infographic) {
          setInnerInfographic({
            ...innerInfographic,
            image: infographic.image,
          });
        }
        setInfographicSaving(false);
        notification.success({
          message: "Success",
          description:
            file.type === "application/pdf"
              ? "PDF document uploaded successfully"
              : "Image uploaded successfully",
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const errorMessage = error.response.data.message;
        notification.error({
          message: "Error",
          description: errorMessage,
        });
        setInfographicSaving(false);
      }
    } else {
      setInfographicImageFile({
        ...infographicImageFile,
        image: file,
      });
      if (innerInfographic)
        setInnerInfographic({
          ...innerInfographic,
          image: { ...innerInfographic.image, url: URL.createObjectURL(file) },
        });
    }
  };

  const handleThumbnailUpload = async (file: File) => {
    if (innerInfographic?._id) {
      setInfographicSaving(true);
      const infographic = await uploadInfographiThumbnail(
        innerInfographic._id,
        file
      );

      if (infographic) {
        setInnerInfographic({
          ...innerInfographic,
          thumbnail: infographic.thumbnail,
        });
      }
      setInfographicSaving(false);
    } else {
      setInfographicImageFile({
        ...infographicImageFile,
        thumbnail: file,
      });

      if (innerInfographic)
        setInnerInfographic({
          ...innerInfographic,
          thumbnail: {
            ...innerInfographic.thumbnail,
            url: URL.createObjectURL(file),
          },
        });
    }
  };

  const handleSaving = async () => {
    setInfographicSaving(true);
    const values = form.getFieldsValue();

    if (innerInfographic?._id) {
      try {
        const updatedInfographic = await updateInfographic(
          innerInfographic._id,
          values
        );
        setInnerInfographic({
          ...innerInfographic,
          ...updatedInfographic,
        });
        setLanguage(updatedInfographic.language);
        setSelectedKey(updatedInfographic.publisher);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setInfographicSaving(false);

        return notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      }
    } else {
      try {
        let createdInfographic = await createInfographic(values);

        if (infographicImageFile.image)
          createdInfographic = await uploadInfographicImage(
            createdInfographic._id,
            infographicImageFile.image
          );

        if (infographicImageFile.thumbnail)
          createdInfographic = await uploadInfographiThumbnail(
            createdInfographic._id,
            infographicImageFile.thumbnail
          );

        if (createdInfographic) {
          setInnerInfographic(createdInfographic);
          navigate(`/content-management/infographic/${createdInfographic._id}`);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const errorMessage = error.response.data.message;
        setInfographicSaving(false);
        return notification.error({
          message: "Error",
          description: errorMessage,
        });
      }
    }

    fetch();
    setInfographicSaving(false);
    openNotificationSuccess();
  };

  const handleDeletion = async () => {
    if (innerInfographic?._id)
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this infographic?</p>,
        async onOk() {
          await deleteInfographic(innerInfographic._id);
          navigate("/content-management/infographic/");
        },
        onCancel() {},
      });
  };

  const handleSearchChange = (
    value: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    event?.preventDefault();

    if (!value.length) {
      setSearchArticleList([]);
      setSearchVideoList([]);
    }
    setSearchValue(value);
  };

  const handleAssociatedArticleAdd = async (articleId: string) => {
    if (innerInfographic) {
      const updatedInfographic = await addInfographicArticle(
        innerInfographic._id,
        articleId
      );
      if (updatedInfographic) {
        setInnerInfographic({
          ...innerInfographic,
          associatedArticles: updatedInfographic.associatedArticles,
        });
      }
    }
  };

  const handleAssociatedArticleRemove = async (articleId: string) => {
    if (innerInfographic) {
      const updatedInfographic = await removeInfographicArticle(
        innerInfographic._id,
        articleId
      );
      if (updatedInfographic) {
        setInnerInfographic({
          ...innerInfographic,
          associatedArticles: updatedInfographic.associatedArticles,
        });
      }
    }
  };

  const handleAssociatedVideoAdd = async (videoId: string) => {
    if (!innerInfographic) return;

    try {
      const { associatedContent } = await addContentToContent(
        innerInfographic._id,
        videoId
      );

      setInnerInfographic({
        ...innerInfographic,
        associatedContent,
      });
      notification.success({
        message: "Success",
        description: "Video added to infographic",
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    }
  };

  const handleAssociatedVideoRemove = async (videoId: string) => {
    if (!innerInfographic) return;

    try {
      const { associatedContent } = await removeContentFromContent(
        innerInfographic._id,
        videoId
      );

      setInnerInfographic({
        ...innerInfographic,
        associatedContent,
      });
      notification.success({
        message: "Success",
        description: "Video removed from infographic",
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    }
  };

  const togglePublish = async () => {
    if (innerInfographic) {
      if (innerInfographic?.meta?.status === "draft") {
        await publishInfographic(innerInfographic._id);
        notification.success({
          message: "Infographic published",
          description: "The infographic was succesfully published.",
        });
      } else {
        await unpublishInfographic(innerInfographic._id);
        notification.success({
          message: "Infographic unpublished",
          description: "The infographic was succesfully unpublished.",
        });
      }

      setInnerInfographic({
        ...innerInfographic,
        meta: {
          ...innerInfographic.meta,
          status:
            innerInfographic?.meta?.status === "draft" ? "published" : "draft",
        },
      });
    }
  };

  const renderPublisherInput = () => {
    if (!selectedKey) return null;

    switch (selectedKey) {
      case SupportedPublisher.COMPANY:
        return (
          <Select
            showSearch
            placeholder='Company'
            filterOption={searchFilterOptions}
            options={companyList
              .filter(
                (company) =>
                  !languageField || company.language === languageField
              )
              .map((company) => ({
                label: `${
                  company.name
                } (${company.language.toLocaleUpperCase()})`,
                value: company._id,
              }))}
          />
        );

      case SupportedPublisher.JOURNAL:
        return (
          <Select
            options={journalList.map((journal) => ({
              label: journal.name,
              value: journal._id,
            }))}
            placeholder='Journal'
          />
        );

      case SupportedPublisher.CONGRESS:
        return (
          <Select
            options={congressList.map((congress) => ({
              label: congress.name,
              value: congress._id,
            }))}
            placeholder='Congress'
          />
        );

      default:
        return null;
    }
  };

  const formatInitialValues = (infographic: IInfographic | undefined) => {
    if (!infographic) return {};

    return {
      ...innerInfographic,
      company: innerInfographic?.company?._id,
      owner: innerInfographic?.owner?._id,
      congress: innerInfographic?.congress?._id,
      medical_specialties: innerInfographic?.medical_specialties.map(
        (tag) => (tag as ITag)?._id
      ),
      tags: innerInfographic?.tags.map((tag) => (tag as ITag)._id),
      publication_date: innerInfographic?.publication_date
        ? moment(innerInfographic?.publication_date)
        : null,
    };
  };

  useEffect(() => {
    const fetchArticles = async () => {
      const query = {
        limit: 10,
        offset: 0,
        language: language,
      };

      let articleList;

      if (searchValue) {
        articleList = await searchArticlesV2({
          ...query,
          status: "published",
          search: searchValue,
        });

        if (articleList.hits.hits) {
          setSearchArticleList(
            articleList.hits.hits.map(
              (article: { _source: { core: IArticle; id: string } }) => {
                return { ...article._source.core, _id: article._source.id };
              }
            )
          );
        }
      }
    };

    const fetchVideos = async () => {
      const query = {
        limit: 10,
        offset: 0,
        status: "published" as FiltersDrawerValuesModel["status"],
        language,
      };

      if (searchValue) {
        const videoList = await searchVideos(searchValue, query);

        if (videoList.hits) {
          setSearchVideoList(
            videoList.hits.map(
              (video: { _source: { core: IVideo; id: string } }) => {
                return { ...video._source.core, _id: video._source.id };
              }
            )
          );
        }
      }
    };

    if (selectedTab === "articles") fetchArticles();
    if (selectedTab === "videos") fetchVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, searchValue, selectedTab]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infographicSaving]);

  if (!innerInfographic && !location.pathname.includes("/create"))
    return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <Row justify='space-between' align='middle'>
        <div>
          <Row align='middle'>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
            <div>
              <h1>
                {infographicId ? "Edit infographic" : "Create infographic"}
              </h1>
              <h3 style={{ margin: 0 }}>{innerInfographic?.title}</h3>
            </div>
          </Row>
        </div>
        <div>
          {infographicId ? (
            <Tag
              style={{ padding: "5px 7px", fontSize: "14px" }}
              color={
                innerInfographic?.meta?.status === "draft"
                  ? "warning"
                  : "success"
              }
            >
              {innerInfographic?.meta?.status?.toUpperCase()}
            </Tag>
          ) : null}
          <Button style={{ marginRight: "10px" }} onClick={togglePublish}>
            {innerInfographic?.meta?.status === "draft"
              ? "Publish "
              : "Unpublish"}
          </Button>
        </div>
      </Row>
      <br />
      <div className='site-layout-content'>
        <Spin spinning={infographicSaving} tip='Infographic saving...'>
          <Tabs
            defaultActiveKey='1'
            onChange={(tab) => setSelectedTab(tab as TabsType)}
          >
            <TabPane tab='Details' key='details'>
              <Row gutter={48}>
                <Col span={12}>
                  <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleSaving}
                    initialValues={formatInitialValues(innerInfographic)}
                  >
                    <Form.Item name='language' label='Language' required>
                      <Select
                        options={languageOptions}
                        placeholder='Language'
                      />
                    </Form.Item>
                    <Form.Item name='title' label='Title' required>
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item
                      name='publication_date'
                      label='Publication Date'
                      required
                    >
                      <DatePicker
                        placeholder='Publication date'
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item name='publisher' label='Publisher' required>
                      <Select
                        placeholder='Publisher'
                        onChange={(value) => setSelectedKey(value)}
                        options={Object.entries(SupportedPublisher).map(
                          ([key, value]) => ({
                            label: capitalize(key),
                            value: value,
                          })
                        )}
                      />
                    </Form.Item>

                    {selectedKey && (
                      <Form.Item
                        name={selectedKey}
                        label={capitalize(selectedKey)}
                        required
                      >
                        {renderPublisherInput()}
                      </Form.Item>
                    )}

                    <Form.Item
                      name='medical_specialties'
                      label='Medical specialties'
                      required
                    >
                      <Select
                        mode='multiple'
                        placeholder='Medical specialties'
                        onChange={(values) =>
                          setSelectedMedicalSpecialties(values)
                        }
                        options={tagList
                          .filter((el) => !el.parent)
                          .map((tag) => ({
                            label: tag.translations.en,
                            value: tag._id,
                          }))}
                        filterOption={(input: string, option) => {
                          if (!option?.label) return false;
                          return option.label
                            .toLocaleLowerCase()
                            .includes(slugify(input).toLocaleLowerCase());
                        }}
                      />
                    </Form.Item>

                    <Form.Item name='tags' label='Tags'>
                      <Select
                        options={tagList
                          .filter((el) => !!el.parent)
                          .filter((el) =>
                            selectedMedicalSpecialties.includes(
                              el.parent?._id || ""
                            )
                          )
                          .map((tag) => ({
                            name: tag.translations.en,
                            label: (
                              <span>
                                {tag.translations.en}
                                <small>{` (${tag.parent?.translations.en})`}</small>
                              </span>
                            ),
                            value: tag._id,
                          }))}
                        mode='multiple'
                        placeholder='Tags'
                        filterOption={(input: string, option) => {
                          if (!option?.label) return false;
                          return option.name
                            .toLocaleLowerCase()
                            .includes(slugify(input).toLocaleLowerCase());
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>

                <Col span={12}>
                  <p style={{ marginBottom: "16px" }}>Image:</p>
                  <ImageUploader
                    images={
                      innerInfographic?.image &&
                      !innerInfographic?.image?.path?.includes(".pdf")
                        ? [innerInfographic.image]
                        : []
                    }
                    inputLegendImage={() => {
                      return;
                    }}
                    onChange={(file) => handleImageUpload(file)}
                  />

                  <Spacer height={36} />
                  <p style={{ marginBottom: "16px" }}>Thumbnail:</p>
                  <ImageUploader
                    images={
                      innerInfographic?.thumbnail
                        ? [innerInfographic.thumbnail]
                        : []
                    }
                    inputLegendImage={() => {
                      return;
                    }}
                    onChange={(file) => handleThumbnailUpload(file)}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab='Rooms' key='rooms'>
              <Form
                layout='vertical'
                form={form}
                onFinish={handleSaving}
                initialValues={formatInitialValues(innerInfographic)}
              >
                {innerInfographic && (
                  <RoomsAssociator
                    contentId={innerInfographic._id}
                    type={ContentFormatsEnum.INFOGRAPHIC}
                    isPublic={innerInfographic.isPublic}
                    ownerId={innerInfographic.owner?._id}
                  />
                )}
              </Form>
            </TabPane>
            <TabPane
              tab='Articles'
              key='articles'
              disabled={!innerInfographic?._id}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0",
                }}
              >
                <Col span={11}>
                  <h3 style={{ marginBottom: "20px" }}>Current articles</h3>
                  {innerInfographic?.associatedArticles?.map(
                    (article: IArticle) => (
                      <Card key={article.slug} style={{ marginBottom: "20px" }}>
                        <p>{article.title}</p>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: red.primary,
                            float: "right",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleAssociatedArticleRemove(article._id);
                          }}
                        >
                          Remove
                        </Button>
                      </Card>
                    )
                  )}
                </Col>
                <Col span={11}>
                  <h3 style={{ marginBottom: "20px" }}>Article list</h3>
                  <Input.Search
                    placeholder='Search article'
                    allowClear
                    style={{
                      marginBottom: "20px",
                    }}
                    onSearch={handleSearchChange}
                  />
                  {searchArticleList?.map((article: IArticle) => (
                    <Card key={article.slug} style={{ marginBottom: "20px" }}>
                      <p>{article.title}</p>
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='info-form-button'
                        style={{ float: "right" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleAssociatedArticleAdd(article._id);
                        }}
                        disabled={innerInfographic?.associatedArticles.some(
                          (associatedArticle: IArticle) =>
                            associatedArticle._id === article._id
                        )}
                      >
                        Add new article
                      </Button>
                    </Card>
                  ))}
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab='Videos'
              key='videos'
              disabled={!innerInfographic?._id}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0",
                }}
              >
                <Col span={11}>
                  <h3 style={{ marginBottom: "20px" }}>Current videos</h3>
                  {innerInfographic?.associatedContent?.map(
                    (video: IContent) => (
                      <Card key={video._id} style={{ marginBottom: "20px" }}>
                        <p>{video.title}</p>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: red.primary,
                            float: "right",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleAssociatedVideoRemove(video._id);
                          }}
                        >
                          Remove
                        </Button>
                      </Card>
                    )
                  )}
                </Col>
                <Col span={11}>
                  <h3 style={{ marginBottom: "20px" }}>Videos list</h3>
                  <Input.Search
                    placeholder='Search video'
                    allowClear
                    style={{
                      marginBottom: "20px",
                    }}
                    onSearch={handleSearchChange}
                  />
                  {searchVideoList?.map((video: IVideo) => (
                    <Card key={video._id} style={{ marginBottom: "20px" }}>
                      <p>{video.title}</p>
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='info-form-button'
                        style={{ float: "right" }}
                        onClick={() => handleAssociatedVideoAdd(video._id)}
                        disabled={innerInfographic?.associatedContent.some(
                          (associatedArticle: IContent) =>
                            associatedArticle._id === video._id
                        )}
                      >
                        Add new video
                      </Button>
                    </Card>
                  ))}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          <Divider />

          <Row justify='end'>
            <div style={{ height: 16 }} />

            <Button
              type='primary'
              className='infographic-form-button'
              onClick={() => form.submit()}
            >
              {infographicId ? "Save infographic" : "Create infographic"}
            </Button>
            {infographicId ? (
              <Button
                danger
                style={{
                  marginLeft: "20px",
                }}
                onClick={handleDeletion}
                disabled={
                  (user || false) &&
                  !checkPermission(
                    user,
                    PermissionLabel.Content,
                    PermissionEnum.Delete
                  )
                }
              >
                Delete infographic
              </Button>
            ) : null}
          </Row>
        </Spin>
      </div>
    </div>
  );
};
