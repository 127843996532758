import { useLocation } from "react-router-dom";
import { LOCAL_STORAGE_FILTERS_KEY } from "~/components/shared/FiltersDrawer";
import { pathToSlug } from "./helpers";

export function useCheckFiltersSaved() {
  const location = useLocation();
  const saves = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}"
  );
  const slugPath = pathToSlug(location.pathname);

  return !!saves[slugPath];
}
