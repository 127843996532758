import React from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";

import { IMapping } from "../../config";
import { MultipleInput } from "./MultipleInput";
import { ITag } from "../../model";
import * as _ from "lodash";
import moment from "moment";

interface IFormMappingProps {
  formMapping: { [key: string]: IMapping };
  id: string;
  hideLabel?: boolean;
  disableAll?: boolean;
  handleRefresh?: () => void;
  handleSearch?: (value: string) => void;
}

export const FormMapping: React.FC<IFormMappingProps> = ({
  formMapping,
  hideLabel,
  id,
  disableAll = false,
  handleRefresh,
  handleSearch,
}) => {
  const { label, type, placeholder } = formMapping && formMapping[id];

  return formMapping[id].array ? (
    <MultipleInput
      key={id}
      id={id}
      label={!hideLabel ? label : ""}
      type={type}
      selectOptions={(formMapping[id].enum as string[]) || []}
      required={formMapping[id].required || false}
    />
  ) : (
    <Form.Item
      key={id}
      label={!hideLabel ? label : ""}
      name={id}
      required={formMapping[id].required}
      hidden={formMapping[id].hidden}
      rules={[
        {
          required: formMapping[id].required,
          message: `Field ${label} is required.`,
        },
      ]}
    >
      {formMapping[id].type === "text" ? (
        <Input placeholder={placeholder || label} disabled={disableAll} />
      ) : formMapping[id].type === "textarea" ? (
        <Input.TextArea
          style={{ minHeight: "180px" }}
          placeholder={placeholder || label}
          disabled={disableAll}
        />
      ) : formMapping[id].type === "number" ? (
        <InputNumber
          placeholder={placeholder || label}
          min={formMapping[id].min}
          max={formMapping[id].max}
          disabled={disableAll}
        />
      ) : formMapping[id].type === "date" ? (
        <DatePicker
          disabledDate={(current) => {
            return current && current > moment().endOf("day");
          }}
        />
      ) : formMapping[id].type === "select" ? (
        <Select
          showSearch={formMapping[id]?.search}
          onSearch={handleSearch}
          onChange={handleRefresh}
          placeholder={placeholder || label || "Please select"}
          disabled={disableAll}
        >
          {(formMapping[id].enum as string[])?.map(
            (option: string, index: number) => (
              <Select.Option key={`${option}_${index}`} value={option}>
                {option}
              </Select.Option>
            )
          )}
        </Select>
      ) : formMapping[id].type === "multiple" ? (
        <Select
          onChange={handleRefresh}
          mode='multiple'
          size='middle'
          placeholder='Please select'
          style={{ width: "100%" }}
          disabled={disableAll}
        >
          {(formMapping[id].enum as string[]).map(
            (option: string, index: number) => (
              <Select.Option key={`${option}_${index}`} value={option}>
                {option}
              </Select.Option>
            )
          )}
        </Select>
      ) : formMapping[id].type === "multipleTag" ? (
        <Select
          onChange={handleRefresh}
          showSearch
          mode='multiple'
          size='middle'
          placeholder='Please select'
          style={{ width: "100%" }}
          disabled={disableAll}
        >
          {Array.from(
            new Set(
              (formMapping[id].enum as ITag[]).map((tag: ITag) => tag.uid)
            )
          )?.map((uid: string, index: number) => {
            const option = _.find(formMapping[id].enum as ITag[], {
              uid,
            }) as ITag;
            return (
              <Select.Option key={`${option.uid}_${index}`} value={option.uid}>
                {`${option.translations["en"]} ${
                  option?.parent?.uid
                    ? `(${option.parent.translations["en"]})`
                    : ""
                }`}
              </Select.Option>
            );
          })}
        </Select>
      ) : formMapping[id].type === "search" ? (
        <Select
          onChange={handleRefresh}
          showSearch
          placeholder={placeholder || label || "Please select"}
          style={{ width: "100%" }}
          disabled={disableAll}
        >
          {(formMapping[id].enum as string[])?.map(
            (option: string, index: number) => (
              <Select.Option key={`${option}_${index}`} value={option}>
                {option}
              </Select.Option>
            )
          )}
        </Select>
      ) : null}
    </Form.Item>
  );
};
