import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "~/context/global.context";
import { MonitoringContext } from "~/context/monitoring.context";
import { ContentFormatsEnum } from "~/model";

export const Flex = (props: {
  justify?: "start" | "end" | "center" | "space-between" | "space-around";
  align?: "start" | "end" | "center";
  flexDirection?: "row" | "column";
  gap?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => (
  <div
    className={props.className}
    style={{
      display: "flex",
      justifyContent: props.justify || "start",
      alignItems: props.align || "start",
      gap: props.gap || 0,
      flexDirection: props.flexDirection || "row",
      width: "100%",
      ...props.style,
    }}
  >
    {props.children}
  </div>
);

export const Spacer = (props: { height?: number }) => (
  <div style={{ height: `${props.height || 24}px`, width: "100%" }} />
);

export const ContentMonitoringCardLabel = (contentType: ContentFormatsEnum) => {
  const { tagList, organisationList } = useContext(GlobalContext);
  const { filters } = useContext(MonitoringContext);

  const selectedSpecialty = tagList.find(
    (spe) => spe._id === filters.medicalSpecialties
  )?.translations?.en;

  const selectedOrganisation = organisationList.find(
    (org) => org._id === filters.organisations
  )?.name;

  return (
    <div>
      {`Total number of ${selectedOrganisation || ""} ${contentType}s ${
        !selectedOrganisation ? "\nacross organizations" : ""
      }\n`}
      {selectedSpecialty ? (
        <>
          in <span>{selectedSpecialty}</span>
        </>
      ) : (
        " "
      )}
    </div>
  );
};

export const ContentMonitoringDateCardLabel = (
  contentType: ContentFormatsEnum
) => {
  const { tagList, organisationList } = useContext(GlobalContext);
  const { filters } = useContext(MonitoringContext);

  const selectedSpecialty = tagList.find(
    (spe) => spe._id === filters.medicalSpecialties
  );

  const selectedOrganisation = organisationList.find(
    (org) => org._id === filters.organisations
  )?.name;

  return (
    <div>
      <>
        {`Most recently uploaded ${contentType}`}
        {selectedOrganisation ? `\ncreated by ${selectedOrganisation}` : ""}
        {selectedSpecialty ? (
          <>
            {"\n in "}
            <Link to={`/reference-data/tag/${selectedSpecialty.uid}`}>
              {selectedSpecialty?.translations?.en}
            </Link>
          </>
        ) : (
          ""
        )}
      </>
    </div>
  );
};
