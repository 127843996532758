import { IMapping } from ".";
import {
  ICompany,
  ICongress,
  IJournal,
  ITag,
  IUser,
  SupportedLanguage,
} from "../model";

export const createVideoMapping = (
  language: SupportedLanguage,
  congressList?: ICongress[],
  companyList?: ICompany[],
  journalList?: IJournal[],
  tagList?: ITag[],
  parentList?: string[],
  userList?: IUser[]
): { [key: string]: IMapping } => ({
  language: {
    label: "Language",
    type: "select",
    enum: Object.values(SupportedLanguage),
    required: true,
  },
  title: {
    label: "Title",
    type: "text",
    required: true,
  },
  publication_date: {
    label: "Publication date",
    type: "date",
    required: true,
  },

  embedURL: {
    label: "Embed URL",
    type: "text",
    required: false,
  },
  videoFormat: {
    label: "Video format",
    type: "select",
    enum: ["article", "story"],
    required: true,
  },
  company: {
    label: "Company",
    type: "search",
    placeholder: "Select a Company",
    enum: companyList
      ?.filter((company: ICompany) => company.language === language)
      .map((company: ICompany) => company.name),
    required: false,
  },
  journal: {
    label: "Journal",
    type: "search",
    placeholder: "Select a Journal",
    enum: journalList?.map((journal: IJournal) => journal.name),
    required: false,
  },
  congress: {
    label: "Congress",
    type: "select",
    placeholder: "Select a Congress",
    enum: congressList?.map((congress: ICongress) => congress.name) as string[],
    required: false,
  },
  user: {
    label: "User",
    placeholder: "Select a User",
    type: "select",
    search: true,
    enum: userList?.map((user: IUser) => user.email) as string[],
    required: false,
  },
  medical_specialties: {
    label: "Medical specialties",
    type: "multipleTag",
    enum: tagList?.filter(
      (tag: ITag) => !Object.keys(tag).includes("parent")
    ) as ITag[],
    required: true,
  },
  tags: {
    label: "Tags",
    type: "multipleTag",
    enum: tagList?.filter((tag: ITag) =>
      parentList?.length ? parentList.includes(tag?.parent?.uid as never) : true
    ) as ITag[],
    required: false,
  },
  keywords: {
    label: "Keywords",
    type: "text",
    array: true,
    required: false,
  },
});

export const updateVideoMapping = (
  tagList?: ITag[],
  parentList?: string[] | []
): IMapping => ({
  label: "Tags",
  type: "multipleTag",
  enum: tagList?.filter((tag: ITag) =>
    parentList?.length ? parentList.includes(tag?.parent?.uid as never) : true
  ) as ITag[],
  location: "card",
  required: false,
});
