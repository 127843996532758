/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Tabs,
  Tag,
} from "antd";

import { red } from "@ant-design/colors";
import {
  LeftOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import {
  addArticleToContent,
  createVideo,
  deleteArticleFromContent,
  deleteVideo,
  getVideo,
  listCongress,
  publishVideo,
  searchArticlesV2,
  searchUsers,
  unpublishVideo,
  updateVideo,
  uploaAPIVideo,
} from "../services";
import {
  IVideo,
  ITag,
  ICompany,
  IArticle,
  IUser,
  ICongress,
  IQuery,
  SupportedLanguage,
  PermissionLabel,
  PermissionEnum,
  ContentFormatsEnum,
} from "../model";
import { IMapping } from "../config";
import {
  createVideoMapping,
  updateVideoMapping,
} from "../config/createVideoMapping";
import { FormMapping } from "./shared/FormMapping";
import { useDropzone } from "react-dropzone";
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "../config/dropzone";
import * as _ from "lodash";
import { GlobalContext } from "../context/global.context";
import { ICreateVideoDto, IUpdateVideoDto } from "../model/dto/video-dto";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingLayout from "./shared/LoadingLayout";
import { checkPermission } from "~/utils/helpers";
import RoomsAssociator from "./shared/RoomsAssociator";
import { Flex } from "./shared/global";

const { confirm } = Modal;

export const VideoEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const videoId = params.id;
  const isCreationMode = location.pathname.includes("create");

  const { companyList, journalList, tagList, organisationList, user } =
    useContext(GlobalContext);
  const search = new URLSearchParams(location.search);
  const [innerVideo, setInnerVideo] = useState<IVideo | null>(null);
  const [innerAPIVideo, setInnerAPIVideo] = useState<IVideo["apiVideo"]>({});
  const [videoMapping, setVideoMapping] = useState<{
    [key: string]: IMapping;
  }>({});
  const [videoSaving, setVideoSaving] = useState<boolean>(false);
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );
  const [medicalSpecialties, setMedicalSpecialties] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [congressList, setCongressList] = useState<ICongress[]>([]);
  const [searchArticleList, setSearchArticleList] = useState<IArticle[]>([]);
  const [searchUserList, setSearchUserList] = useState<IUser[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const {
    acceptedFiles,
    inputRef,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "video/*": [] },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchArticles = async () => {
    const query = {
      limit: 10,
      offset: 0,
      language,
    };

    let articleList;

    if (searchValue) {
      articleList = await searchArticlesV2({
        ...query,
        status: "published",
        search: searchValue,
      });

      if (articleList.hits.hits) {
        setSearchArticleList(
          articleList.hits.hits.map(
            (article: { _source: { core: IArticle; id: string } }) => ({
              ...article._source.core,
              _id: article._source.id,
            })
          )
        );
      }
    }
  };

  const fetch = async () => {
    fetchCongressList();

    if (videoId && !innerVideo) {
      const video = await getVideo(videoId);

      if (video) {
        if (video.apiVideo) {
          setInnerAPIVideo(video.apiVideo);
        }
        setLanguage(video.language);
        setSelectedKey(video.publisher);
        setInnerVideo({
          ...video,
          medical_specialties: (video.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (video.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }
    }
  };

  const fetchCongressList = async () => {
    const query: IQuery = {
      limit: 20,
      offset: 0,
      language,
    };
    const congressList = await listCongress(query);
    setCongressList(congressList.docs);
  };

  const handleReturn = () => {
    navigate(`/content-management/video/?language=${language}`);
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Video saved",
      description: "The video was succesfully saved.",
    });
  };

  const openNotificationError = () => {
    notification["error"]({
      message: "Error",
      description: "An error occurred.",
    });
  };

  const handleEdition = async (
    values: ICreateVideoDto | IUpdateVideoDto
  ): Promise<any> => {
    Object.keys(values).forEach(async (key: string) => {
      if (key === "congress" && values.congress) {
        values["congress"] = _.find(congressList, {
          name: values["congress"],
          language,
        })?.name;
      }
      if (key === "company" && values.company) {
        values["company"] = _.find(companyList, {
          name: values["company"],
          language,
        })?._id;
      }
      if (key === "journal" && values.journal) {
        if (journalList.length) {
          const journal = _.find(journalList, { name: (values as any)[key] });
          if (journal) {
            values[key] = journal.uid;
          }
        }
      }
      if (key === "owner" && values.owner) {
        const organisation = organisationList.find(
          (org) => org.name === values.owner
        );
        if (organisation) {
          return (values[key] = organisation._id);
        }
      }

      if ((values as any)[key] === "yes") {
        (values as any)[key] = true;
      }
      if ((values as any)[key] === "no") {
        (values as any)[key] = false;
      }
      if (key !== "isPublic" && !(values as any)[key]) {
        delete (values as any)[key];
      }
    });

    values.publisher = selectedKey;

    return values as any;
  };

  const handleSearch = async (value: string) => {
    const users = await searchUsers(value, { limit: 10 });
    setSearchUserList(users);
  };

  const populateValues = async (): Promise<any> => {
    const values = await handleEdition(form.getFieldsValue());

    if (values.congress) {
      if (congressList.length) {
        const congress = congressList.filter(
          (congress: ICongress) => congress.name === values.congress
        )[0];
        if (congress) {
          values.congress = congress;
        }
      }
    }

    if (values.company) {
      if (companyList.length) {
        const company = companyList.filter(
          (company: ICompany) => company._id === values.company
        )[0];
        if (company) {
          values.company = company;
        }
      }
    }

    if (values.journal) {
      if (journalList.length) {
        const journal = _.find(journalList, { uid: values.journal });
        if (journal) {
          values.journal = journal;
        }
      }
    }

    if (values.medical_specialties) {
      if (tagList.length) {
        const medical_specialties = _.filter(tagList, (tag: ITag) =>
          _.includes(values.medical_specialties, tag.uid)
        );
        if (medical_specialties) {
          values.medical_specialties = medical_specialties;
        }
      }
    }

    if (values.owner) {
      values.owner = organisationList.find((org) => org._id === values.owner);
    }

    if (values.tags) {
      if (tagList.length) {
        const tags = _.filter(tagList, (tag: ITag) =>
          _.includes(values.tags, tag.uid)
        );
        if (tags) {
          values.tags = tags;
        }
      }
    }

    if (values.user) {
      if (searchUserList.length) {
        const user = searchUserList.filter(
          (user: IUser) => user.email === values.user
        )[0];
        if (user) {
          values.user = user;
        }
      }
    }

    return values as any;
  };

  const handleRefresh = async () => {
    const values = await populateValues();

    setInnerVideo({
      ...innerVideo,
      ...values,
      meta: innerVideo?.meta,
    });

    const newMedicalSpecialties = values?.medical_specialties?.map(
      (tag: ITag) => tag.uid
    );
    const newTags = values?.tags?.map((tag: ITag) => tag.uid);

    const parentList = newMedicalSpecialties?.map(
      (medicalSpecialty: string) => {
        return _.find(tagList, {
          uid: medicalSpecialty,
        })?.uid;
      }
    ) as string[];

    setMedicalSpecialties(newMedicalSpecialties);
    setTags(newTags);
    setSelectedKey(values.publisher);
    setLanguage(values.language);

    setVideoMapping({
      ...videoMapping,
      tags: updateVideoMapping(tagList, parentList),
    });
  };

  const populateToDto = async () => {
    const newValues = await populateValues();

    if (!newValues.publisher) {
      throw new Error("Publisher is required");
    }

    if (newValues.congress) {
      newValues.congress = newValues.congress._id;
    }

    if (newValues.company) {
      newValues.company = newValues.company._id;
    }

    if (newValues.journal) {
      newValues.journal = newValues.journal._id;
    }

    if (newValues.owner) {
      newValues.owner = newValues.owner._id;
    }

    if (newValues.medical_specialties) {
      newValues.medical_specialties = newValues.medical_specialties.map(
        (tag: ITag) => tag._id
      );
    }

    if (newValues.tags) {
      newValues.tags = newValues.tags.map((tag: ITag) => tag._id);
    }

    if (newValues.keywords)
      newValues.keywords = newValues.keywords.filter((el: string) => !!el);

    return newValues;
  };

  const handleSaving = async () => {
    setVideoSaving(true);
    try {
      const newValues = await populateToDto();

      if (videoId && innerVideo) {
        const updatedVideo = await updateVideo(
          innerVideo._id,
          newValues as IUpdateVideoDto
        );
        if (acceptedFiles.length) {
          const uploadedAPIVideo = await uploaAPIVideo(
            innerVideo._id,
            acceptedFiles[0]
          );
          if (uploadedAPIVideo) {
            setInnerAPIVideo(uploadedAPIVideo.apiVideo);
            acceptedFiles.splice(0, acceptedFiles.length);
            if (inputRef.current) inputRef.current.value = "";
          }
        }
        setInnerVideo({
          ...updatedVideo,
          medical_specialties: (updatedVideo.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (updatedVideo.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
        setLanguage(updatedVideo.language);
        setSelectedKey(updatedVideo.publisher);
        setVideoSaving(false);
      } else {
        const createdVideo = await createVideo(newValues as ICreateVideoDto);
        if (createdVideo) {
          if (acceptedFiles.length) {
            const uploadedAPIVideo = await uploaAPIVideo(
              createdVideo._id,
              acceptedFiles[0]
            );
            if (uploadedAPIVideo) {
              setInnerAPIVideo(uploadedAPIVideo.apiVideo);
              acceptedFiles.splice(0, acceptedFiles.length);
              if (inputRef.current) inputRef.current.value = "";
            }
          }
          openNotificationSuccess();
          setVideoSaving(false);
          window.location.href = `/content-management/video/${createdVideo.slug}`;
        } else {
          openNotificationError();
          setVideoSaving(false);
        }
      }
    } catch (error) {
      const errorMessage = (error as Error).message;

      notification.error({
        message: "An error occurred",
        description: errorMessage,
      });

      setVideoSaving(false);
    }
  };

  const handleDeletion = async () => {
    if (videoId && innerVideo)
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this video?</p>,
        async onOk() {
          await deleteVideo(innerVideo._id);
          navigate("/content-management/video/");
          notification.success({
            message: "Video deleted",
            description: "The video was succesfully deleted.",
          });
        },
        onCancel() {},
      });
  };

  const handleSelectedKeyChange = (key: string) => {
    setSelectedKey(key);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleAssociatedArticleAdd = async (articleId: string) => {
    if (innerVideo) {
      const updatedVideo = await addArticleToContent(innerVideo._id, articleId);

      setInnerVideo({
        ...innerVideo,
        associatedArticles: updatedVideo.associatedArticles,
      });

      notification.success({
        message: "Article added to video successfuly",
      });
    }
  };

  const handleAssociatedArticleRemove = async (articleId: string) => {
    if (innerVideo) {
      const updatedVideo = await deleteArticleFromContent(
        innerVideo._id,
        articleId
      );

      setInnerVideo({
        ...innerVideo,
        associatedArticles: updatedVideo.associatedArticles,
      });

      notification.success({
        message: "Article removed from video successfuly",
      });
    }
  };

  const togglePublish = async () => {
    if (innerVideo) {
      try {
        const updatedVideo =
          innerVideo?.meta?.status === "draft"
            ? await publishVideo(innerVideo._id)
            : await unpublishVideo(innerVideo._id);
        setInnerVideo({
          ...innerVideo,
          meta: {
            ...innerVideo.meta,
            status:
              innerVideo?.meta?.status === "draft" ? "published" : "draft",
          },
        });
        notification.success({
          message: "Video updated",
          description: "The video was succesfully updated.",
        });
        setLanguage(updatedVideo.language);
      } catch (error: any) {
        const errorMessage = error.response.data.message;
        notification.error({
          message: "Error",
          description: errorMessage,
        });
      }
    }
  };

  const handleFinishFailed = ({ errorFields }: any) => {
    // Afficher une notification pour chaque champ ayant une erreur
    errorFields.forEach((error: any) => {
      notification.error({
        message: "Validation Error",
        description: error.errors[0], // Premier message d'erreur du champ
        placement: "topRight",
      });
    });
  };

  useEffect(() => {
    fetchArticles();
  }, [searchValue]);

  useEffect(() => {
    fetch();
  }, [videoSaving]);

  useEffect(() => {
    const filterCongressList = congressList.filter(
      (congress: ICongress) => congress.language === language
    );

    const newvideoMapping = createVideoMapping(
      language as SupportedLanguage,
      filterCongressList, // congressList
      companyList,
      journalList,
      tagList,
      (innerVideo?.medical_specialties as string[]) || [],
      searchUserList
    );

    setVideoMapping(newvideoMapping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    journalList,
    tagList,
    organisationList,
    innerVideo,
    language,
    searchUserList,
    congressList,
  ]);

  useEffect(() => {
    fetchCongressList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  if ((!innerVideo && !isCreationMode) || !tagList.length)
    return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row justify='space-between' align='middle'>
          <div>
            <Row align='middle'>
              <Button
                onClick={handleReturn}
                shape='circle'
                style={{ marginRight: 20 }}
              >
                <LeftOutlined />
              </Button>
              <div>
                <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
                  {videoId ? "Edit video" : "Create video"}
                </h1>
                <h3 style={{ marginTop: 0 }}>{innerVideo?.title}</h3>
              </div>
            </Row>
          </div>
          <div>
            <Flex gap={4} align='center'>
              {videoId ? (
                <Tag
                  style={{ padding: "5px 7px", fontSize: "14px", margin: 0 }}
                  color={
                    innerVideo?.meta?.status === "draft" ? "warning" : "success"
                  }
                >
                  {innerVideo?.meta?.status?.toUpperCase()}
                </Tag>
              ) : null}
              <Divider type='vertical' />
              <Button icon={<CloudUploadOutlined />} onClick={togglePublish}>
                {innerVideo?.meta?.status === "draft"
                  ? "Publish "
                  : "Unpublish"}
              </Button>
            </Flex>
          </div>
        </Row>
        <br />
        <div className='site-layout-content'>
          <Spin spinning={videoSaving} tip='Video saving...'>
            <Form
              form={form}
              layout='vertical'
              name='video_edition'
              onFinishFailed={handleFinishFailed}
              fields={
                innerVideo
                  ? Object.keys({
                      ...innerVideo,
                      associatedArticles: [],
                    }).map((key: string) => ({
                      key: key,
                      name: [key],
                      value:
                        key === "journal"
                          ? innerVideo.journal?.name
                          : key === "congress"
                          ? innerVideo.congress?.name
                          : key === "company"
                          ? innerVideo.company?.name
                          : key === "owner"
                          ? innerVideo.owner?.name
                          : key === "medical_specialties" &&
                            medicalSpecialties?.length
                          ? medicalSpecialties
                          : key === "publication_date"
                          ? moment(
                              innerVideo.publication_date
                                ? innerVideo.publication_date
                                : moment(new Date())
                            )
                          : key === "tags" && tags?.length
                          ? tags
                          : key === "user" && innerVideo.user
                          ? innerVideo.user?.email
                          : key === "associatedArticles"
                          ? innerVideo?.associatedArticles?.map(
                              (article: IArticle) => article.title
                            )
                          : innerVideo[key as keyof ICreateVideoDto],
                    }))
                  : []
              }
              onFinish={handleSaving}
            >
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Details' key='1'>
                  <Row gutter={20}>
                    <Col span={12}>
                      {Object.keys(videoMapping)
                        .filter((key: string) => {
                          return (
                            (selectedKey === undefined && key === "company") ||
                            selectedKey === key ||
                            ![
                              "congress",
                              "company",
                              "journal",
                              "user",
                            ].includes(key)
                          );
                        })
                        .map((key: string, index: number) => {
                          return [
                            "congress",
                            "company",
                            "journal",
                            "user",
                          ].includes(key) ? (
                            <div key={key + index}>
                              <Form.Item label='Publisher' required>
                                <Select
                                  placeholder='Select a publisher'
                                  key={index}
                                  value={selectedKey}
                                  onChange={handleSelectedKeyChange}
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <Select.Option
                                    key={"congress"}
                                    value={"congress"}
                                  >
                                    Congress
                                  </Select.Option>
                                  <Select.Option
                                    key={"company"}
                                    value={"company"}
                                  >
                                    Company
                                  </Select.Option>
                                  <Select.Option
                                    key={"journal"}
                                    value={"journal"}
                                  >
                                    Journal
                                  </Select.Option>
                                  <Select.Option key={"user"} value={"user"}>
                                    User
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <div style={{ paddingLeft: 24 }}>
                                <FormMapping
                                  hideLabel
                                  key={key}
                                  formMapping={videoMapping}
                                  id={key}
                                  handleRefresh={handleRefresh}
                                  handleSearch={handleSearch}
                                />
                              </div>
                            </div>
                          ) : (
                            <FormMapping
                              key={key}
                              formMapping={videoMapping}
                              id={key}
                              handleRefresh={handleRefresh}
                            />
                          );
                        })}
                    </Col>
                    <Col span={12}>
                      <Form.Item name='externalLink' label='External link'>
                        <Input placeholder='https://example.com' />
                      </Form.Item>
                      <Form.Item
                        name='sourceURL'
                        label='Source URL'
                        tooltip='The full-video version link shared via an external link.'
                      >
                        <Input placeholder='Source URL' />
                      </Form.Item>
                      <Form.Item key='video' label='API Video' name='video'>
                        <div style={{ display: "flex" }}>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              maxFiles: 1,
                            })}
                          >
                            <input {...getInputProps({ style })} />
                          </div>
                          {innerAPIVideo?.videoId ? (
                            <img
                              key={innerAPIVideo.videoId}
                              style={{ maxHeight: "72px", marginLeft: "10px" }}
                              src={innerAPIVideo.thumbnail}
                              alt={innerAPIVideo.thumbnail}
                            />
                          ) : null}
                        </div>
                      </Form.Item>

                      <Form.Item
                        key='transcription'
                        label='Transcription'
                        name='transcription'
                      >
                        <Input.TextArea
                          placeholder='Transcription of the video'
                          rows={20}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab='Articles' key='2'>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "20px 0",
                    }}
                  >
                    <Col span={11}>
                      <h3 style={{ marginBottom: "20px" }}>Current articles</h3>
                      {innerVideo?.associatedArticles?.map(
                        (article: IArticle) => (
                          <Card
                            key={article.slug}
                            style={{ marginBottom: "20px" }}
                          >
                            <p>{article.title}</p>
                            <Button
                              style={{
                                color: "white",
                                backgroundColor: red.primary,
                                float: "right",
                              }}
                              onClick={() =>
                                handleAssociatedArticleRemove(article._id)
                              }
                            >
                              Remove
                            </Button>
                          </Card>
                        )
                      )}
                    </Col>
                    <Col span={11}>
                      <h3 style={{ marginBottom: "20px" }}>Article list</h3>
                      <Input.Search
                        placeholder='Search article'
                        allowClear
                        style={{
                          marginBottom: "20px",
                        }}
                        onSearch={handleSearchChange}
                      />
                      {searchArticleList?.map((article: IArticle) => (
                        <Card
                          key={article.slug}
                          style={{ marginBottom: "20px" }}
                        >
                          <p>{article.title}</p>
                          <Button
                            type='primary'
                            className='info-form-button'
                            style={{ float: "right" }}
                            onClick={() =>
                              handleAssociatedArticleAdd(article._id)
                            }
                            disabled={innerVideo?.associatedArticles.some(
                              (associatedArticle: IArticle) =>
                                associatedArticle._id === article._id
                            )}
                          >
                            Add new article
                          </Button>
                        </Card>
                      ))}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab='Rooms' key='3'>
                  {innerVideo && (
                    <RoomsAssociator
                      contentId={innerVideo._id}
                      type={ContentFormatsEnum.VIDEO}
                      ownerId={innerVideo.owner?._id}
                      isPublic={innerVideo.isPublic}
                    />
                  )}
                </TabPane>
              </Tabs>

              <Divider />
              <Row justify='end'>
                <div style={{ height: 16 }} />
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='video-form-button'
                  >
                    {videoId ? "Save video" : "Create video"}
                  </Button>
                  {videoId ? (
                    <Button
                      danger
                      style={{
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                      disabled={
                        (user || false) &&
                        !checkPermission(
                          user,
                          PermissionLabel.Content,
                          PermissionEnum.Delete
                        )
                      }
                    >
                      Delete video
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
