import { Col, Divider, Row } from "antd";
import MetricsCard from "../../components/MetricsCard";
import {
  EyeOutlined,
  HeartOutlined,
  PaperClipOutlined,
  ShareAltOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useContext } from "react";
import { ContentsMonitorModel, IContent } from "~/model";
import LoadingLayout from "~/components/shared/LoadingLayout";
import CustomColumnsPlot from "../../components/monitoring/CustomColumnsPlot";
import { Spacer } from "~/components/shared/global";
import PerformanceTop10Table from "./PerformanceTop10Table";
import { getContentLink, getContentType } from "~/utils/helpers";
import { GlobalContext } from "~/context/global.context";

const PerformanceMetricsContent = () => {
  const { monitoring } = useContext(GlobalContext);

  const monitoringData = monitoring?.contents;

  const reduceContentMetric = (
    metric: "saveds" | "likes" | "shares" | "views"
  ) => {
    if (!monitoringData) return 0;

    const { totalContent } = monitoringData;

    const totalValue = Object.keys(totalContent)
      .map(
        (key) =>
          totalContent[key as keyof ContentsMonitorModel["totalContent"]][
            metric
          ]
      )
      .reduce((acc, value) => acc + value, 0);

    return totalValue;
  };

  // TODO: Remove limitation this when the API is fixed
  const formatMetricsBySpecialty = (
    key: "medicalSpecialty" | "tags" = "medicalSpecialty"
  ) => {
    if (!monitoringData) return [];

    const { metricsByMedicalSpecialty, metricsByTag } = monitoringData;

    if (key === "tags")
      return metricsByTag
        ?.map((metric) => ({
          label: metric.tag?.translations.en,
          value: metric.views > 100000 ? 100000 : metric.views || 0,
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 100); // TODO: remove the splice

    return metricsByMedicalSpecialty
      ?.map((metric) => ({
        label: metric.medicalSpecialty?.translations.en,
        value: metric.views > 100000 ? 100000 : metric.views || 0,
      }))
      .sort((a, b) => b.value - a.value);
  };

  const getTop10Content = (metric: "views" | "saveds" | "likes" | "shares") => {
    if (!monitoringData) return [];

    const { topArticles, topNectars, topPlaylists, topVideos } = monitoringData;

    const topContent = [
      ...topArticles,
      ...topNectars,
      ...topPlaylists,
      ...topVideos,
    ]
      .sort((a, b) => (b.metrics?.[metric] ?? 0) - (a.metrics?.[metric] ?? 0))
      .slice(0, 10)
      .map((content) => ({
        id: content.id || content._id,
        title: content.title,
        contentType: getContentType(content as IContent),
        creationDate: content.meta.creationDate,
        link: getContentLink(content as IContent),
        owner: content.owner?.name,
      }));

    return topContent;
  };

  if (!monitoringData) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <h1>{"Performance Metrics: All Contents"}</h1>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <MetricsCard
            title='Total Content Views'
            icon={<EyeOutlined />}
            value={reduceContentMetric("views")}
          />
        </Col>
        <Col span={12}>
          <MetricsCard
            title='Total Content Likes'
            icon={<HeartOutlined />}
            value={reduceContentMetric("likes")}
          />
        </Col>
        <Col span={12}>
          <MetricsCard
            title='Total Content Saves'
            icon={<PaperClipOutlined />}
            value={reduceContentMetric("saveds")}
          />
        </Col>
        <Col span={12}>
          <MetricsCard
            title='Total Content Shares'
            icon={<ShareAltOutlined />}
            value={reduceContentMetric("shares")}
          />
        </Col>
      </Row>

      <Spacer />

      <CustomColumnsPlot
        icon={<TagOutlined />}
        title={"Consumed content by specialty"}
        data={formatMetricsBySpecialty("medicalSpecialty")}
      />

      <Spacer />

      <CustomColumnsPlot
        icon={<TagOutlined />}
        title={"Consumed content by tags"}
        data={formatMetricsBySpecialty("tags")}
        color='#FF8800'
      />

      <Spacer />

      <Row gutter={[24, 24]}>
        <Col xl={24} xxl={12}>
          <PerformanceTop10Table
            icon={<EyeOutlined />}
            title='Top 10 most Viewed content'
            data={getTop10Content("views")}
          />
        </Col>
        <Col xl={24} xxl={12}>
          <PerformanceTop10Table
            icon={<HeartOutlined />}
            title='Top 10 most Liked content'
            data={getTop10Content("likes")}
          />
        </Col>
        <Col xl={24} xxl={12}>
          <PerformanceTop10Table
            icon={<PaperClipOutlined />}
            title='Top 10 most Saved content'
            data={getTop10Content("saveds")}
          />
        </Col>
        <Col xl={24} xxl={12}>
          <PerformanceTop10Table
            icon={<ShareAltOutlined />}
            title='Top 10 most Shared content'
            data={getTop10Content("shares")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PerformanceMetricsContent;
