import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Layout,
  Row,
  Spin,
  Tabs,
  Input,
  Tag,
  Modal,
  Divider,
  Select,
} from "antd";

import { red } from "@ant-design/colors";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";

import {
  getPlaylist,
  createPlaylist,
  deletePlaylist,
  updatePlaylist,
  uploadPlaylistImage,
  listCompanies,
  removePlaylistArticle,
  addPlaylistArticle,
  searchArticlesV2,
  publishPlaylist,
  unpublishPlaylist,
  getCertifiedUsers,
  deleteContentFromPlaylist,
  searchVideos,
  addContentToPlaylist,
  searchNectar,
} from "../services";
import {
  IPlaylist,
  IImage,
  ICompany,
  ITag,
  IArticle,
  IUser,
  SupportedLanguage,
  ContentFormatsEnum,
  IContent,
  PermissionLabel,
  PermissionEnum,
} from "../model";
import {
  ICreatePlaylistDto,
  IUpdatePlaylistDto,
} from "../model/dto/playlist-dto";
import {
  createPlaylistMapping,
  updatePlaylistMapping,
} from "../config/createPlaylistMapping";
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "../config/dropzone";
import * as _ from "lodash";
import { FormMapping } from "./shared/FormMapping";
import { IMapping } from "../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext, IGlobalContext } from "../context/global.context";
import moment from "moment";
import { Spacer } from "./shared/global";
import { checkPermission, searchFilterOptions } from "~/utils/helpers";
import RoomsAssociator from "./shared/RoomsAssociator";
import { AxiosError } from "axios";
import { FiltersDrawerValuesModel } from "./shared/FiltersDrawer";

export const PlaylistEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { tagList, organisationList } = useContext(
    GlobalContext
  ) as IGlobalContext;
  const { user } = useContext(GlobalContext) as IGlobalContext;
  const playlistId = params.id;
  const search = new URLSearchParams(location.search);
  const [innerPlaylist, setInnerPlaylist] = useState<
    IPlaylist & { meta?: IPlaylist["meta"] }
  >();
  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );
  const [playlistMapping, setPlaylistMapping] = useState<{
    [key: string]: IMapping;
  }>({});
  const [innerImages, setInnerImages] = useState<string[]>([]);
  const [playlistSaving, setPlaylistSaving] = useState<boolean>(false);
  const [innerSearchList, setInnerSearchList] = useState<
    IContent[] | IArticle[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [certifiedUsers, setCertifiedUsers] = useState<IUser[] | null>(null);
  const [activePane, setActivePane] = useState<string>("0");
  const [innerPublisher, setInnerPublisher] = useState<{
    key: string | null;
    value: string | null;
  }>({
    key: null,
    value: null,
  });
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchMappings = () => {
    const newPlaylistMapping = createPlaylistMapping(tagList);

    setPlaylistMapping(newPlaylistMapping);
  };

  const fetchPlaylist = async () => {
    if (playlistId && !innerPlaylist) {
      const playlist = await getPlaylist(playlistId);
      setInnerPublisher({
        key: playlist?.user ? "user" : "company",
        value: playlist?.user ? playlist?.user : playlist?.company?._id,
      });

      setInnerPlaylist({
        ...playlist,
        medical_specialties: (
          (playlist.medical_specialties as ITag[]) ?? []
        ).map((tag: ITag) => tag.uid),
        tags: ((playlist.tags as ITag[]) ?? []).map((tag: ITag) => tag.uid),
      });
      setLanguage(playlist.language);
      if (playlist.images.length) {
        setInnerImages(playlist.images.map((image: IImage) => image.url));
      }
    }
  };

  const fetchOrganisationsCompanies = async () => {
    if (!innerPlaylist) {
      const newPlaylistMapping = createPlaylistMapping(tagList);

      setPlaylistMapping(newPlaylistMapping);

      return;
    }

    if (!Object.keys(playlistMapping).length) {
      setPlaylistMapping(createPlaylistMapping([], []));

      if (tagList.length) {
        let parentList: string[] = [];
        if (innerPlaylist && innerPlaylist.medical_specialties) {
          parentList = (innerPlaylist.medical_specialties as string[]).map(
            (medicalSpecialty: string) =>
              _.find(tagList, {
                uid: medicalSpecialty,
              })?.uid
          ) as string[];
        }
        const newPlaylistMapping = createPlaylistMapping(tagList, parentList);

        setPlaylistMapping(newPlaylistMapping);
      }
    }
  };

  const fetchArticles = async (limit?: number, offset?: number) => {
    const query = {
      limit: limit || 10,
      offset: offset || 0,
      language,
    };

    let articleList;

    if (searchValue) {
      articleList = await searchArticlesV2({
        ...query,
        status: "published",
        search: searchValue,
      });

      if (articleList.hits.hits) {
        setInnerSearchList(
          articleList.hits.hits.map(
            (article: { _source: { core: IArticle } }) => article._source.core
          )
        );
      }
    }
  };

  const fetchVideos = async (limit?: number, offset?: number) => {
    const query = {
      limit: limit || 10,
      offset: offset || 0,
      status: "published" as FiltersDrawerValuesModel["status"],
      language,
    };

    let videoList;

    if (searchValue) {
      videoList = await searchVideos(searchValue, query);

      if (videoList.hits) {
        setInnerSearchList(
          videoList.hits.map(
            (video: { _source: { view: IContent; id: string } }) => ({
              ...video._source.view,
              id: video._source.id,
            })
          )
        );
      }
    }
  };

  const fetchNectars = async () => {
    if (!searchValue) return;

    const { docs: nectarList } = await searchNectar(searchValue);

    if (nectarList) setInnerSearchList(nectarList);
    else setInnerSearchList([]);
  };

  const fetchCompanies = async () => {
    if (!innerPlaylist) return;

    const language = innerPlaylist.language;
    const companyList = await listCompanies({ language });
    setCompanyList(companyList);
  };

  const fetchCertifiedUsers = async () => {
    const users = await getCertifiedUsers();
    setCertifiedUsers(users);
  };

  const handleReturn = () => {
    navigate(-1);
    // navigate(
    //   `/content-management/playlist?language=${language}&kol=${
    //     search.get("kol") === "true"
    //   }`
    // );
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Playlist saved",
      description: "The playlist was succesfully saved.",
    });
  };

  const openNotificationError = (description: string) => {
    notification["error"]({
      message: "An error occurred",
      description,
    });
  };

  const handleEdition = async (
    values: ICreatePlaylistDto | IUpdatePlaylistDto
  ) => {
    if (values.company) {
      values.company = _.find(companyList, {
        name: values.company,
        language,
      })?._id;
    }

    if ((values.sponsored as unknown as string) === "yes") {
      values.sponsored = true;
    }
    if ((values.sponsored as unknown as string) === "no") {
      values.sponsored = false;
    }

    return values;
  };

  const handleSaving = async (
    values: ICreatePlaylistDto | IUpdatePlaylistDto
  ) => {
    setPlaylistSaving(true);
    const newValues = await handleEdition(values);

    if (newValues.medical_specialties) {
      newValues.medical_specialties = newValues.medical_specialties.map(
        (medicalSpecialty: string) => {
          return (
            _.find(tagList, {
              uid: medicalSpecialty,
            })?._id || ""
          );
        }
      );
    }

    if (newValues.tags) {
      newValues.tags = newValues.tags.map((tag: string) => {
        return (
          _.find(tagList, {
            uid: tag,
          })?._id || ""
        );
      });
    }

    if (playlistId) {
      if (!innerPublisher.value) {
        openNotificationError("Please select a publisher");
        setPlaylistSaving(false);
        return;
      }

      const playlistDto = {
        ...newValues,
      };

      playlistDto[innerPublisher.key as keyof ICreatePlaylistDto] =
        innerPublisher.value as never;

      if (innerPublisher.key === "company") playlistDto.user = null;
      else playlistDto.company = null;

      const updatedPlaylist = await updatePlaylist(playlistId, playlistDto);

      if (acceptedFiles.length) {
        const playlist = await uploadPlaylistImage(
          playlistId,
          acceptedFiles[0]
        );
        if (playlist.images.length) {
          setInnerImages(playlist.images.map((image: IImage) => image.url));
        }
      }

      setInnerPlaylist({
        ...updatedPlaylist,
        medical_specialties: (
          updatedPlaylist.medical_specialties as ITag[]
        ).map((tag: ITag) => tag._id),
        tags: (updatedPlaylist.tags as ITag[]).map((tag: ITag) => tag._id),
      });
      setLanguage(updatedPlaylist.language);
      setPlaylistSaving(false);
      openNotificationSuccess();
    } else {
      try {
        if (!innerPublisher.value) {
          openNotificationError("Please select a publisher");
          setPlaylistSaving(false);
          return;
        }

        const playlistDto = {
          ...newValues,
        };

        playlistDto[innerPublisher.key as keyof ICreatePlaylistDto] =
          innerPublisher.value as never;

        const createdPlaylist = await createPlaylist(
          playlistDto as ICreatePlaylistDto
        );

        if (createdPlaylist) {
          navigate(`/content-management/playlist/${createdPlaylist._id}`);
        }
        setLanguage(createdPlaylist.language);
        setPlaylistSaving(false);
        openNotificationSuccess();
      } catch (error: unknown) {
        setPlaylistSaving(false);
        openNotificationError(
          (
            error as {
              response: { data: { message: string } };
            }
          ).response.data.message
        );
      }
    }
  };

  const handleRefresh = async () => {
    const fieldsValue = form.getFieldsValue();
    const values = await handleEdition(fieldsValue);

    if (values.company) {
      if (companyList.length) {
        const company = _.find(companyList, { _id: values.company });
        if (company) {
          values.company = company as unknown as string;
        }
      }
    }

    if (values.owner)
      values.owner = organisationList.find(
        (org) => org._id === values.owner
      ) as unknown as string;

    setInnerPlaylist({
      ...(values as unknown as IPlaylist),
      meta: innerPlaylist?.meta as IPlaylist["meta"],
    });

    const newMedicalSpecialties = values.medical_specialties;

    const parentList = newMedicalSpecialties?.map(
      (medicalSpecialty: string) => {
        return _.find(tagList, {
          uid: medicalSpecialty,
        })?.uid;
      }
    ) as string[];

    setPlaylistMapping({
      ...playlistMapping,
      tags: updatePlaylistMapping(tagList, parentList),
    });
  };

  const handleAddContent = async (contentId: string) => {
    if (!playlistId)
      return openNotificationError(
        "The playlist must be created before adding an article."
      );

    setPlaylistSaving(true);
    try {
      const newPlaylist =
        activePane === ContentFormatsEnum.ARTICLE
          ? await addPlaylistArticle(playlistId, contentId)
          : await addContentToPlaylist({ contentId, playlistId });

      if (innerPlaylist && newPlaylist) {
        setInnerPlaylist({
          ...newPlaylist,
          medical_specialties: (newPlaylist.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (newPlaylist.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }
      setPlaylistSaving(false);
      openNotificationSuccess();
    } catch (error) {
      openNotificationError(
        (error as AxiosError<{ message: string }>)?.response?.data?.message ||
          "An error occurred"
      );
      setPlaylistSaving(false);
    }
  };

  const handleDeleteContent = async (contentId: string) => {
    if (!playlistId)
      return openNotificationError(
        "The playlist must be created before removing an article."
      );

    try {
      setPlaylistSaving(true);
      const newPlaylist =
        activePane === ContentFormatsEnum.ARTICLE
          ? await removePlaylistArticle(playlistId, contentId)
          : await deleteContentFromPlaylist({
              contentId,
              playlistId,
            });

      if (newPlaylist) {
        setInnerPlaylist({
          ...newPlaylist,
          medical_specialties: (newPlaylist.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (newPlaylist.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }
      setPlaylistSaving(false);
      openNotificationSuccess();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: "An error occurred",
        description: error.response.data.message,
      });
      setPlaylistSaving(false);
    }
  };

  const handleDeletion = async () => {
    if (playlistId) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this playlist?</p>,
        async onOk() {
          await deletePlaylist(playlistId);
          notification.success({
            message: "Playlist deleted",
            description: "The playlist was succesfully deleted.",
          });
          navigate("/content-management/playlist/");
        },
      });
    }
  };

  const convertBoolean = (value: boolean) => {
    return value ? "yes" : "no";
  };

  const togglePublish = async () => {
    if (innerPlaylist) {
      const updatedPlaylist =
        innerPlaylist?.meta?.status === "draft"
          ? await publishPlaylist(innerPlaylist._id)
          : await unpublishPlaylist(innerPlaylist._id);
      setInnerPlaylist({
        ...updatedPlaylist,
        medical_specialties: (
          updatedPlaylist.medical_specialties as ITag[]
        ).map((tag: ITag) => tag.uid),
        tags: (updatedPlaylist.tags as ITag[]).map((tag: ITag) => tag.uid),
      });
    }
  };

  useEffect(() => {
    fetchPlaylist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId]);

  useEffect(() => {
    fetchOrganisationsCompanies();
    fetchCertifiedUsers();
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerPlaylist?.language]);

  useEffect(() => {
    if (!searchValue) setInnerSearchList([]);
    else if (activePane === ContentFormatsEnum.ARTICLE) fetchArticles();
    else if (activePane === ContentFormatsEnum.VIDEO) fetchVideos();
    else fetchNectars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (
      activePane === ContentFormatsEnum.ARTICLE ||
      activePane === ContentFormatsEnum.VIDEO
    ) {
      setSearchValue("");
      setInnerSearchList([]);
    }
  }, [activePane]);

  useEffect(() => {
    fetchMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationList, tagList]);

  const contentTabPanes = [
    {
      title: "Article",
      type: ContentFormatsEnum.ARTICLE,
      contentList: innerPlaylist?.playlist,
    },
    {
      title: "Video",
      type: ContentFormatsEnum.VIDEO,
      contentList: innerPlaylist?.playlistContent?.filter(
        (el) => el.content_format === ContentFormatsEnum.VIDEO
      ),
    },
    {
      title: "Nectar",
      type: ContentFormatsEnum.NECTAR,
      contentList: innerPlaylist?.playlistContent?.filter(
        (el) => el.content_format === ContentFormatsEnum.NECTAR
      ),
    },
  ];

  if (!innerPlaylist && playlistId)
    return (
      <Layout>
        <Layout.Content style={{ padding: "50px" }}>
          <Row justify='space-between' align='middle'>
            <Spin />
          </Row>
        </Layout.Content>
      </Layout>
    );

  return (
    <div className='basic-layout'>
      <Row justify='space-between' align='middle'>
        <Row align='middle'>
          <div>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
          </div>
          <h1 style={{ margin: 0 }} onClick={() => console.log(innerPlaylist)}>
            {playlistId ? "Edit playlist" : "Create playlist"}
          </h1>
        </Row>
        <div>
          {playlistId ? (
            <Tag
              style={{ padding: "5px 7px", fontSize: "14px" }}
              color={
                innerPlaylist?.meta?.status === "draft" ? "warning" : "success"
              }
            >
              {innerPlaylist?.meta?.status?.toUpperCase()}
            </Tag>
          ) : null}
          <Button style={{ marginRight: "10px" }} onClick={togglePublish}>
            {innerPlaylist?.meta?.status === "draft" ? "Publish " : "Unpublish"}
          </Button>
        </div>
      </Row>
      <Spacer />
      <div className='site-layout-content'>
        <Spin spinning={playlistSaving} tip='Playlist saving...'>
          <Form
            form={form}
            layout='vertical'
            name='playlist_edition'
            fields={
              innerPlaylist &&
              Object.keys(innerPlaylist).map((key: string) => ({
                name: [key],
                value:
                  key === "sponsored"
                    ? convertBoolean(innerPlaylist.sponsored)
                    : key === "owner"
                    ? innerPlaylist.owner?._id
                    : key === "publicationDate"
                    ? moment(
                        innerPlaylist.publicationDate
                          ? innerPlaylist.publicationDate
                          : moment(new Date())
                      )
                    : key === "medical_specialties"
                    ? tagList
                        .filter(
                          (specialty: ITag) =>
                            (
                              innerPlaylist.medical_specialties as string[]
                            )?.includes(specialty._id) ||
                            (
                              innerPlaylist.medical_specialties as string[]
                            )?.includes(specialty.uid)
                        )
                        .map((tag: ITag) => tag.uid)
                    : key === "tags"
                    ? tagList
                        .filter(
                          (tag: ITag) =>
                            (innerPlaylist.tags as string[])?.includes(
                              tag._id
                            ) ||
                            (innerPlaylist.tags as string[])?.includes(tag.uid)
                        )
                        .map((tag: ITag) => tag.uid)
                    : innerPlaylist[key as keyof ICreatePlaylistDto],
              }))
            }
            onFinish={handleSaving}
          >
            <Tabs
              defaultActiveKey={"1"}
              onChange={(activeKey) => setActivePane(activeKey)}
            >
              <TabPane tab='Details' key='1'>
                <Row
                  style={{
                    paddingBottom: "20px",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                  justify='space-between'
                  gutter={48}
                >
                  <Col span={12}>
                    <Form.Item name='isPublic' label='Accessibility' required>
                      <Select placeholder='Accessibility'>
                        <Select.Option value={true}>
                          {"✅ Public"}
                        </Select.Option>
                        <Select.Option value={false}>
                          {"🔐 Private"}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {Object.keys(playlistMapping).map((key: string) => (
                      <FormMapping
                        key={key}
                        formMapping={playlistMapping}
                        id={key}
                        handleRefresh={handleRefresh}
                      />
                    ))}
                    {playlistId ? (
                      <Form.Item key='image' label='Image' name='image'>
                        <div style={{ display: "flex" }}>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              maxFiles: 1,
                            })}
                          >
                            <input {...getInputProps({ style })} />
                          </div>
                          {innerImages ? (
                            <img
                              key={innerImages[innerImages.length - 1]}
                              style={{
                                maxHeight: "72px",
                                marginLeft: "10px",
                              }}
                              src={innerImages[innerImages.length - 1]}
                              alt={innerImages[innerImages.length - 1]}
                            />
                          ) : null}
                        </div>
                      </Form.Item>
                    ) : null}
                  </Col>

                  <Col span={12}>
                    <Divider>Company / KOL *</Divider>
                    <Select
                      style={{ width: "100%" }}
                      placeholder='Publisher'
                      defaultValue={
                        playlistId
                          ? innerPlaylist?.user
                            ? "user"
                            : "company"
                          : undefined
                      }
                      onChange={(publisher: string) =>
                        setInnerPublisher({
                          key: publisher,
                          value: null,
                        })
                      }
                    >
                      <Select.Option value='company'>Company</Select.Option>
                      <Select.Option value='user'>KOL User</Select.Option>
                    </Select>
                    <div style={{ height: 16 }} />

                    <Select
                      style={{ width: "100%" }}
                      notFoundContent='Please select a language first'
                      placeholder={
                        innerPublisher.key === "company"
                          ? "Select a company"
                          : "Select a certified user"
                      }
                      showSearch
                      filterOption={searchFilterOptions}
                      disabled={!innerPublisher}
                      value={innerPublisher.value}
                      options={
                        innerPublisher.key === "company"
                          ? companyList?.map((company) => ({
                              value: company._id,
                              label: company.name + ` (${company.language})`,
                            }))
                          : certifiedUsers?.map((user: IUser) => ({
                              value: user._id,
                              label: user.firstname + " " + user.lastname,
                            }))
                      }
                      onChange={(value: string) =>
                        setInnerPublisher({
                          ...innerPublisher,
                          value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab='Rooms' key={"2"}>
                {playlistId && (
                  <RoomsAssociator
                    contentId={playlistId}
                    type={"playlist"}
                    isPublic={innerPlaylist?.isPublic}
                    ownerId={innerPlaylist?.owner?._id}
                  />
                )}
              </TabPane>

              {contentTabPanes.map((tabPane) => (
                <TabPane tab={tabPane.title} key={tabPane.type}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "20px 0",
                    }}
                  >
                    <Col span={11}>
                      <h3 style={{ marginBottom: "20px" }}>
                        {`Current ${tabPane.title}s`}
                      </h3>
                      {tabPane.contentList?.map(
                        (content: IArticle | IContent) => (
                          <Card
                            key={content.slug + "current-content"}
                            style={{ marginBottom: "20px" }}
                          >
                            <p>{content.title}</p>
                            <Button
                              style={{
                                color: "white",
                                backgroundColor: red.primary,
                                float: "right",
                              }}
                              htmlType='button'
                              onClick={() =>
                                handleDeleteContent(
                                  activePane === ContentFormatsEnum.ARTICLE
                                    ? content.slug
                                    : content._id
                                )
                              }
                            >
                              Remove
                            </Button>
                          </Card>
                        )
                      )}
                    </Col>
                    <Col span={11}>
                      <h3 style={{ marginBottom: "20px" }}>
                        {`${tabPane.title}s list`}
                      </h3>
                      <Input.Search
                        placeholder={`Search ${tabPane.title}`}
                        allowClear
                        style={{
                          marginBottom: "20px",
                        }}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onSearch={(value) => setSearchValue(value)}
                      />
                      {innerSearchList?.map((content) => (
                        <Card
                          key={content.title + "search-content"}
                          style={{ marginBottom: "20px" }}
                        >
                          <p>{content.title}</p>
                          <Button
                            type='primary'
                            htmlType='button'
                            className='info-form-button'
                            style={{ float: "right" }}
                            onClick={() =>
                              handleAddContent(
                                activePane === ContentFormatsEnum.ARTICLE
                                  ? content.slug
                                  : content.id
                              )
                            }
                          >
                            Add new content
                          </Button>
                        </Card>
                      ))}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </Tabs>

            {parseInt(activePane) <= 2 && (
              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='playlist-form-button'
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {playlistId ? "Save playlist" : "Create playlist"}
                  </Button>
                  {playlistId ? (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                      disabled={
                        (user || false) &&
                        !checkPermission(
                          user,
                          PermissionLabel.Content,
                          PermissionEnum.Delete
                        )
                      }
                    >
                      {"Delete playlist"}
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            )}
          </Form>
        </Spin>
      </div>
    </div>
  );
};
