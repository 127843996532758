import { useEffect, useState } from "react";
import {
  acceptLemonCertifiedRequest,
  getLemonCertifiedRequests,
  rejectLemonCertifiedRequest,
} from "../services";
import {
  Button,
  Col,
  Layout,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LeftOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ILemonRequest, IUser } from "../model";
import { countryList } from "../config/countries";

type columnsType = {
  title: string;
  dataIndex?: string | string[];
  fixed?: "left" | "right" | undefined;
  key: string;
  width?: number;
  render?: (
    text: string,
    record: IUser & { status: string; key: string; requestId: string }
  ) => JSX.Element | string;
};

const LemonRequestList = () => {
  const [requestsList, setRequestsList] = useState<ILemonRequest[]>([]);
  const [filterParams, setFilterParams] = useState<{
    status: ILemonRequest["status"] | null;
  }>({ status: null });

  const fetchRequests = async () => {
    const requests = await getLemonCertifiedRequests({
      limit: 1000,
    });
    setRequestsList(
      requests
        .filter((request) =>
          filterParams.status ? request.status === filterParams.status : true
        )
        .reverse()
    );
  };

  const handleActionRequest = async (
    requestId: string,
    action: "Accept" | "Reject"
  ) => {
    Modal.confirm({
      title: "Are your sure to " + action.toLowerCase() + " this request?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      centered: true,
      onOk: async () => {
        if (action === "Accept") await acceptLemonCertifiedRequest(requestId);
        else if (action === "Reject")
          await rejectLemonCertifiedRequest(requestId);

        await fetchRequests();
        notification.success({
          message:
            "LemonCertified request has been successfully" +
            action.toLowerCase() +
            "ed",
          description: "Request has been successfully processed.",
        });
      },
    });
  };

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const columns: Array<columnsType> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      render: (text: string) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 200,
      render: (code: string) => <span>{countryList[code]}</span>,
    },
    {
      title: "Profession",
      dataIndex: ["profession", "translations", "en"],
      key: "profession",
    },
    {
      title: "Main specialty",
      dataIndex: ["main_specialty", "translations", "en"],
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 110,
      render: (status: string) => (
        <Tag
          icon={
            status === "Approved" ? (
              <CheckCircleOutlined />
            ) : status === "Waiting" ? (
              <SyncOutlined spin />
            ) : (
              <CloseCircleOutlined />
            )
          }
          color={
            status === "Approved"
              ? "green"
              : status === "Waiting"
              ? "orange"
              : "red"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "operation",

      fixed: "right",
      width: 200,
      render: (_, record) => (
        <div
          style={{
            display: record.status === "Waiting" ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Button
            size='small'
            type='link'
            danger
            onClick={() => handleActionRequest(record.requestId, "Reject")}
          >
            Reject
          </Button>
          <Button
            size='small'
            type='primary'
            onClick={() => handleActionRequest(record.requestId, "Accept")}
          >
            Accept
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <Col>
            <Link to='/sensitive-data/organisations'>
              <Button
                type='link'
                icon={<LeftOutlined />}
                style={{ padding: 0 }}
              >
                List Users
              </Button>
            </Link>
            <div style={{ height: 16 }} />
            <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
              <i>Lemon Certified</i> requests
            </h1>
          </Col>
          <Col>
            <b style={{ color: "#000", marginRight: 8 }}>Status:</b>
            <Select
              defaultValue={filterParams.status}
              onChange={(status) => setFilterParams({ status })}
              placeholder='Status'
              style={{ width: 150 }}
            >
              <Select.Option value={null}>All</Select.Option>
              <Select.Option value='Waiting'>Waiting</Select.Option>
              <Select.Option value='Approved'>Approved</Select.Option>
              <Select.Option value='Rejected'>Rejected</Select.Option>
            </Select>
          </Col>
        </Row>
        <Table
          size='small'
          pagination={{ pageSize: 50 }}
          scroll={{
            x: "max-content",
            y: "calc(100vh - 270px)",
          }}
          columns={columns}
          dataSource={requestsList.map((request) => ({
            key: request.id,
            ...request.user,
            requestId: request.id,
            status: request.status,
          }))}
        />
      </Layout.Content>
    </Layout>
  );
};

export default LemonRequestList;
