import { FileOutlined, RadiusUprightOutlined } from "@ant-design/icons";
import { Flex } from "../shared/global";
import MetricCard from "./MetricCard";
import { useContext } from "react";
import { GlobalContext } from "~/context/global.context";
import { ContentFormatsEnum } from "~/model";

type InnerProps = {
  contentType: ContentFormatsEnum;
  dataKey: "articles" | "videos" | "nectars" | "playlists" | "infographics";
};

export default function ContentMonitoringVersus({
  contentType,
  dataKey,
}: InnerProps) {
  const { monitoring } = useContext(GlobalContext);

  return (
    <Flex gap={32} align='center'>
      <MetricCard
        align='right'
        icon={<RadiusUprightOutlined />}
        label={`Current number of draft ${contentType}s\n across medical specialties`}
        title={monitoring ? monitoring[dataKey].byStatus.drafts : "..."}
      />
      <div style={{ fontSize: 32, fontWeight: 600, color: "#252522" }}>
        {"VS"}
      </div>
      <MetricCard
        backgroundColor='#EEF822'
        icon={<FileOutlined />}
        label={`Current number of published ${contentType}s\n across medical specialties`}
        title={monitoring ? monitoring[dataKey].byStatus.published : "..."}
      />
    </Flex>
  );
}
