import { useEffect, useState } from "react";
import { Flex, Spacer } from "./shared/global";
import { IProfession } from "~/model";
import LoadingLayout from "./shared/LoadingLayout";
import { Button, Divider, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link, useNavigate } from "react-router-dom";
import { getProfessionList } from "~/services";

const ProfessionList = () => {
  const navigate = useNavigate();
  const [professions, setProfessions] = useState<{
    data: IProfession[];
    meta: { total: number; offset: number; limit: number };
  } | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfessions = async () => {
      setIsLoading(true);
      const { docs: data, meta } = await getProfessionList({
        offset: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      });
      setIsLoading(false);
      setProfessions({ data, meta });
    };

    fetchProfessions();
  }, [pagination]);

  const handleSearchProfession = async (value: string) => {
    if (!value) {
      setPagination({ current: 1, pageSize: 20 });
    }

    const { docs: results } = await getProfessionList({
      offset: 0,
      limit: 100,
    });

    const filteredResults = results.filter((profession) => {
      return profession.translations.en
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    setProfessions({
      data: filteredResults,
      meta: { total: filteredResults.length, offset: 0, limit: 100 },
    });
  };

  const columns: ColumnsType<IProfession> = [
    {
      title: "Name",
      dataIndex: ["translations", "en"],
      key: "name",
      render: (name: string, profession: IProfession) => (
        <Link to={`/reference-data/profession/${profession._id}`}>{name}</Link>
      ),
    },
    {
      title: "Medical Specialties",
      dataIndex: "medical_specialties",
      key: "medicalSpecialties",
      render: (medicalSpecialties: string[]) => medicalSpecialties.length,
    },
  ];

  if (!professions) {
    return <LoadingLayout />;
  }

  return (
    <div className='basic-layout'>
      <Flex justify='space-between' align='center'>
        <h1
          style={{
            margin: 0,
          }}
          onClick={() => console.log(professions)}
        >
          Profession List
        </h1>
        <Button
          type='primary'
          onClick={() => navigate("/reference-data/profession/create")}
        >
          Create new profession
        </Button>
      </Flex>
      <Spacer />
      <Input.Search
        allowClear
        placeholder='Search profession'
        onChange={(e) => handleSearchProfession(e.target.value)}
      />
      <Divider />
      <Table
        size='small'
        columns={columns}
        dataSource={professions.data.map((pfs) => ({ ...pfs, key: pfs._id }))}
        loading={isLoading}
        pagination={{
          pageSize: professions.meta.limit,
          total: professions.meta.total,
          pageSizeOptions: ["10", "20", "50"],
          current:
            Math.floor(professions.meta.offset / professions.meta.limit) + 1,
          showSizeChanger: true,
          onChange(page, pageSize) {
            setPagination({ current: page, pageSize });
          },
        }}
      />
    </div>
  );
};

export default ProfessionList;
