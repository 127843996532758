import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, message, Modal, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { ContentFormatsEnum, IContent } from "~/model";
import {
  deleteContentFromRoom,
  postContentToRoom,
  searchVideos,
} from "~/services";

const RoomEditionContents = (props: { contents: IContent[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [innerContents, setInnerContents] = useState<IContent[]>(
    props.contents
  );
  const [searchResults, setSearchResults] = useState<IContent[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const searchContents = async () => {
      if (!searchValue.length) return setSearchResults([]);

      const res = await searchVideos(searchValue);

      if (res.hits) {
        setSearchResults(
          res.hits
            .map((content: { _source: { core: IContent; id: string } }) => ({
              ...content._source.core,
              id: content._source.id,
            }))
            .filter((content: IContent) => {
              return !innerContents.find(
                (innerContent) => innerContent._id === content.id
              );
            })
        );
      }
    };

    searchContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleAddContent = async (content: IContent) => {
    try {
      if (!params.id) throw new Error("No room id provided");

      await postContentToRoom(params.id, content.id);

      setInnerContents([content, ...innerContents]);
      message.success("Content added to the room successfully!");
    } catch (error) {
      message.error("An error occured while adding the content to the room");
      throw error;
    }
  };

  const handleDeleteContent = async (contentId: string) => {
    const callback = async () => {
      try {
        if (!params.id) throw new Error("No room id provided");

        await deleteContentFromRoom(params.id, contentId);

        setInnerContents(
          innerContents.filter((content) => content._id !== contentId)
        );
        message.success("Content has been successfully removed from the room!");
      } catch (error) {
        message.error(
          "An error occured while removing the content from the room"
        );
        throw error;
      }
    };

    Modal.confirm({
      title: "Are you sure you want to remove this content from the room?",
      content: "This action is irreversible",
      onOk: callback,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns: ColumnsType<IContent> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: () => (
        <Input.Search
          allowClear
          placeholder='Search contents'
          onSearch={(val) => setSearchFilter(val)}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#ffc408" : undefined }} />
      ),
      render: (_text: string, content: IContent) => (
        <Link
          to={`/content-management/${
            content.content_format === ContentFormatsEnum.INFOGRAPHIC
              ? "infographic"
              : "video"
          }/${content.slug}`}
        >
          {content.title}
        </Link>
      ),
    },
    {
      title: "Format",
      dataIndex: "content_format",
      key: "content_format",
      width: 150,
      render: (text: IContent["content_format"]) =>
        text === ContentFormatsEnum.INFOGRAPHIC ? "📑 Infographic" : "🎥 Video",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 81,
      render: (_text: string, content: IContent) => (
        <Button
          danger
          shape={"circle"}
          icon={<CloseOutlined />}
          onClick={() => handleDeleteContent(content.id)}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          bordered
          columns={columns}
          dataSource={innerContents
            .map((content) => ({
              ...content,
              key: content._id,
            }))
            .filter((content) => {
              if (!searchFilter) return true;
              return content.title
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            })}
        />
      </Col>
      <Col span={8}>
        <Input.Search
          placeholder='Add videos (infographic coming soon...)'
          allowClear
          onSearch={(val) => setSearchValue(val)}
        />
        <Divider />
        {searchResults.length ? (
          <Flex
            gap={8}
            flexDirection='column'
            style={{ height: 800, overflowY: "scroll", overflowX: "hidden" }}
          >
            {searchResults.map((content) => (
              <Card key={`${content.slug}-search`}>
                <p>{content.title}</p>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddContent(content)}
                >
                  Add this Content
                </Button>
              </Card>
            ))}
          </Flex>
        ) : null}
      </Col>
    </Row>
  );
};

export default RoomEditionContents;
