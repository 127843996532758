import { IMapping } from ".";

export const createOrganisationMapping: { [key: string]: IMapping } = {
  name: {
    label: "Name",
    type: "text",
    required: true,
  },
  description: {
    label: "Description",
    type: "textarea",
    required: false,
  },
};
