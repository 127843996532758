import { createContext } from "react";
import {
  ICompany,
  IJournal,
  INectarHighlight,
  IOrganisation,
  IRoom,
  ITag,
  IUserAdmin,
  MonitoringReducerModel,
} from "../model";

export interface IGlobalContext {
  user: IUserAdmin | null;
  updateUser: (logout?: boolean) => void;
  companyList: ICompany[];
  journalList: IJournal[];
  tagList: ITag[];
  organisationList: IOrganisation[];
  highlightNectars: INectarHighlight[] | null;
  roomList: IRoom[] | null;
  monitoring: MonitoringReducerModel | null;
}

const defaultGlobalContext: IGlobalContext = {
  user: null,
  updateUser: () => {
    return;
  },
  companyList: [],
  journalList: [],
  tagList: [],
  organisationList: [],
  highlightNectars: null,
  roomList: null,
  monitoring: null,
};

export const GlobalContext =
  createContext<IGlobalContext>(defaultGlobalContext);
