import { IMapping } from ".";
import { ITag, SupportedLanguage } from "../model";

export const createPlaylistMapping = (
  tagList?: ITag[],
  parentList?: string[]
): { [key: string]: IMapping } => ({
  language: {
    label: "Language",
    type: "select",
    enum: Object.values(SupportedLanguage),
    required: true,
  },
  sponsored: {
    label: "Sponsored",
    type: "select",
    enum: ["yes", "no"],
    hidden: false,
    required: true,
  },
  title: {
    label: "Title",
    type: "text",
    required: true,
  },
  publicationDate: {
    label: "Publication date",
    type: "date",
    required: true,
  },

  rank: {
    label: "Rank",
    type: "number",
    min: 1,
    max: 100,
    required: true,
  },
  medical_specialties: {
    label: "Medical specialties",
    type: "multipleTag",
    enum: tagList?.filter(
      (tag: ITag) => !Object.keys(tag).includes("parent")
    ) as ITag[],
    required: false,
  },
  tags: {
    label: "Tags",
    type: "multipleTag",
    enum: tagList?.filter((tag: ITag) =>
      parentList?.length ? parentList.includes(tag?.parent?.uid as never) : true
    ) as ITag[],
    required: false,
  },
});

export const updatePlaylistMapping = (
  tagList?: ITag[],
  parentList?: string[] | []
): IMapping => ({
  label: "Tags",
  type: "multipleTag",
  enum: tagList?.filter((tag: ITag) =>
    parentList?.length ? parentList.includes(tag?.parent?.uid as never) : true
  ) as ITag[],
  required: false,
});
