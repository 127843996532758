/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Divider, Layout, Pagination, Row, Table, Tag } from "antd";

import { listSponsoredPlaylists, searchPlaylists } from "../services";
import { IOrganisation, IPlaylist, ISorter } from "../model";
import { Link, useLocation } from "react-router-dom";
import { store } from "../store/store";
import { StarOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import FiltersDrawer, {
  FiltersDrawerValuesModel,
} from "./shared/FiltersDrawer";
import { useCheckFiltersSaved } from "~/utils/hooks";

export const PlaylistList: React.FC = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const { organisationList } = store.getState();

  const [innerPlaylistList, setInnerPlaylistList] = useState<IPlaylist[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showKOL, setShowKOL] = useState<boolean>(search.get("kol") === "true");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  const [filters, setFilters] = useState<FiltersDrawerValuesModel>();
  const [sorter, setSorter] = useState<ISorter>();

  const hasSavedFilters = useCheckFiltersSaved();

  const fetch = async (limit?: number, offset?: number) => {
    setIsLoading(true);

    if (!filters && hasSavedFilters) return; // Prevent fetching if filters are saved

    if (filters?.search) {
      const playlistList = await searchPlaylists(filters.search, {
        limit,
        offset,
        languages: filters.languages,
        owners: filters.owners,
        rooms: filters.rooms,
      });

      if (playlistList) {
        if (playlistList.hits.hits) {
          setInnerPlaylistList(
            playlistList.hits.hits.map(
              (res: {
                _source: { core: IPlaylist; filters: IPlaylist; id: string };
              }) => ({
                ...res._source.core,
                ...res._source.filters,
                _id: res._source.id,
                owner: organisationList.find(
                  (org) =>
                    org._id === (res._source.filters.owner as unknown as string)
                ),
              })
            )
          );
          handlePaginationChange(1);
        }
      }
    } else {
      const fetchArgs = {
        ...filters,
        limit,
        offset,
      };

      const playlistList = await listSponsoredPlaylists({
        ...fetchArgs,
        kol: showKOL || undefined,
      });

      if (playlistList) {
        setInnerPlaylistList(playlistList.docs);
        setCount(playlistList?.meta?.total);
      }
    }
    setIsLoading(false);
  };

  const handleChangeFilters = (filters: FiltersDrawerValuesModel) => {
    setFilters(filters);
    handlePaginationChange(1);
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: any,
    sorter: any
  ) => {
    const sortOrder = sorter.order === "ascend" ? "asc" : "desc";

    if (sorter.column?.title === "Creation date")
      setSorter({ sortBy: "creationDate", sortOrder });

    if (sorter.column?.title === "Publication date")
      setSorter({ sortBy: "publicationDate", sortOrder });
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetch(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
    setPage(page);
  };

  useEffect(() => {
    fetch();
  }, [filters, sorter, showKOL]);

  const columns: ColumnsType<IPlaylist> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 700,
      render: (_, playlist: IPlaylist) => {
        if (playlist) {
          return (
            <Link
              to={`/content-management/playlist/${playlist._id}?kol=${showKOL}`}
            >
              {playlist.title}
            </Link>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "meta",
      key: "meta",
      width: 150,
      render: (meta: IPlaylist["meta"]) => {
        return (
          <Tag color={meta?.status === "draft" ? "warning" : "success"}>
            {meta?.status?.toLocaleUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Creation date",
      dataIndex: ["meta", "creationDate"],
      key: "creationDate",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (date: string) => {
        return new Date(date)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "publicationDate",
      key: "publicationDate",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (date: string) => {
        return new Date(date)?.toLocaleDateString();
      },
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 150,
      render: (owner: IOrganisation) => {
        if (!owner) return <span>{"N/A"}</span>;

        return (
          <Link to={`/sensitive-data/organisations/${owner?.uid}`}>
            {owner.name}
          </Link>
        );
      },
    },
    {
      title: "Articles",
      dataIndex: "metrics",
      width: 100,
      key: "metrics",
      render: (metrics: IPlaylist["metrics"]) => {
        return metrics.articles;
      },
    },
    {
      title: "Journals",
      width: 100,
      dataIndex: "metrics",
      key: "metrics",
      render: (metrics: IPlaylist["metrics"]) => {
        return metrics.journals;
      },
    },
    {
      title: "Clicks",
      width: 100,
      dataIndex: "metrics",
      key: "metrics",
      render: (metrics: IPlaylist["metrics"]) => {
        return metrics.views;
      },
    },
    {
      title: "Saves",
      dataIndex: "metrics",
      key: "metrics",
      width: 100,
      render: (metrics: IPlaylist["metrics"]) => {
        return metrics.saveds;
      },
    },
    {
      title: "Shares",
      width: 100,
      dataIndex: "metrics",
      key: "metrics",
      render: (metrics: IPlaylist["metrics"]) => {
        return metrics.shares;
      },
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row justify='space-between' style={{ margin: "20px 0" }}>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Playlists</h1>
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <Button
              icon={<UnorderedListOutlined />}
              type={!showKOL ? "primary" : "default"}
              onClick={() => setShowKOL(false)}
            >
              Playlists
            </Button>
            <Button
              icon={<StarOutlined />}
              type={showKOL ? "primary" : "default"}
              onClick={() => setShowKOL(true)}
            >
              KOL
            </Button>
            <Divider type='vertical' />
            <Link to={"/content-management/playlist/create/"}>
              <Button type='primary'>New playlist</Button>
            </Link>
          </div>
        </Row>
        <FiltersDrawer onChange={handleChangeFilters} />
        <Divider />
        <Table
          sticky
          size='small'
          loading={isLoading}
          columns={columns}
          pagination={false}
          dataSource={innerPlaylistList}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          onChange={handleTableChange}
        />
        <Pagination
          style={{ marginTop: "10px" }}
          onChange={handlePaginationChange}
          total={count}
          current={page}
          defaultCurrent={1}
          defaultPageSize={20}
          pageSizeOptions={["10", "20", "50", "75"]}
        />
      </Layout.Content>
    </Layout>
  );
};
