import React, { useContext, useEffect, useState } from "react";

import { Alert, Button, Col, Form, Image, Input, Layout, Row } from "antd";

import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { authenticate } from "../services";
import { GlobalContext, IGlobalContext } from "../context/global.context";
import { useNavigate } from "react-router-dom";

import JuisciLogoPng from "~/assets/juisci-base.png";
import { IUserRole, PermissionLabel } from "~/model";
import { checkPermission } from "~/utils/helpers";

interface IFormValues {
  email: string;
  password: string;
}

export const LoginPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    invalid: {
      email: false,
      password: false,
    },
  });
  const { user, updateUser } = useContext(GlobalContext) as IGlobalContext;

  const redirectAfterLoginSuccess = () => {
    if (!user) return;

    if (user.roles.includes(IUserRole.SUPER_ADMIN))
      return navigate("/super-admin/roles-permissions");
    if (checkPermission(user, PermissionLabel.Content))
      return navigate("/content-sources/crawling");
    if (checkPermission(user, PermissionLabel.Referential))
      return navigate("/reference-data/company");
    if (checkPermission(user, PermissionLabel.SensitiveData))
      return navigate("/sensitive-data/organisations");
    return navigate("/content-management/articles");
  };

  const handleAuthenticate = async (values: IFormValues) => {
    const { email, password } = values;
    try {
      const { user } = await authenticate(email, password);

      if (user) {
        updateUser();
        setState({
          invalid: {
            email: false,
            password: false,
          },
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.response && error.response.status === 404) {
        setState({
          invalid: {
            email: true,
            password: false,
          },
        });
      } else {
        setState({
          invalid: {
            email: false,
            password: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (user) redirectAfterLoginSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Row justify='center'>
        <Image
          width={260}
          style={{ padding: "50px" }}
          src={JuisciLogoPng}
          preview={false}
        />
      </Row>
      <Row justify='center'>
        <Col xs='6'>
          <Layout>
            <Layout.Content style={{ zIndex: 1, padding: "50px" }}>
              {state.invalid.email ? (
                <Alert
                  message='The provided email is not recognized'
                  type='warning'
                  closable
                />
              ) : null}
              {state.invalid.password ? (
                <Alert
                  message='The provided email is wrong'
                  type='warning'
                  closable
                />
              ) : null}
              <Form
                name='normal_login'
                className='login-form'
                initialValues={{
                  remember: true,
                }}
                onFinish={handleAuthenticate}
              >
                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: "Please input your email",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    placeholder='Email'
                  />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    placeholder='Password'
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Layout.Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
};
