import { LeftOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Select,
} from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Spacer } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";
import { IRoom } from "~/model";
import { CreateQuizzDto } from "~/model/dto/quizz-dto";
import { deleteQuizz, getQuizzList, postQuizz, putQuizz } from "~/services";
import { getRequestErrorMessage, searchFilterOptions } from "~/utils/helpers";

const QuizzEdition = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: quizzId } = useParams<{ id: string }>();
  const { roomList } = useContext(GlobalContext);

  function handleSubmit() {
    if (!quizzId) createQuizz();
    else updateQuizz();
  }

  async function handleDeleteQuizz() {
    Modal.confirm({
      title: "Delete Quizz",
      content: "Are you sure you want to delete this quizz?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          if (!quizzId) throw new Error("Quizz ID invalid");

          setIsLoading(true);
          await deleteQuizz(quizzId);

          notification.success({
            message: "Quizz deleted",
            description: "The quizz has been successfully deleted.",
          });

          navigate(-1);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          notification.error({
            message: "Error",
            description:
              getRequestErrorMessage(error) ||
              "An error has occured while deleting the quizz.",
          });
          return error;
        }
      },
    });
  }

  async function createQuizz() {
    try {
      setIsLoading(true);
      const quizzDto: CreateQuizzDto = {
        title: form.getFieldValue("title"),
        subtitle: form.getFieldValue("subtitle"),
        template: form.getFieldValue("template"),
        room: form.getFieldValue("room"),
        eventName: form.getFieldValue("eventName"),
        displayAt: form.getFieldValue("rangeDate")[0].toISOString(),
        endDisplayAt: form.getFieldValue("rangeDate")[1].toISOString(),
      };

      const response = await postQuizz(quizzDto);

      if (response) {
        notification.success({
          message: "Quizz created",
          description: "The quizz has been successfully created.",
        });

        navigate(`/content-management/quizz/${response.id}`, {
          replace: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description:
          getRequestErrorMessage(error) ||
          "An error has occured while creating the quizz.",
      });
      return error;
    }
  }

  async function updateQuizz() {
    try {
      if (!quizzId) throw new Error("Quizz ID invalid");

      setIsLoading(true);
      const quizzDto: CreateQuizzDto = {
        title: form.getFieldValue("title"),
        subtitle: form.getFieldValue("subtitle"),
        template: form.getFieldValue("template"),
        room: form.getFieldValue("room"),
        eventName: form.getFieldValue("eventName"),
        displayAt: form.getFieldValue("rangeDate")[0].toISOString(),
        endDisplayAt: form.getFieldValue("rangeDate")[1].toISOString(),
      };

      const response = await putQuizz(quizzId, quizzDto);

      if (response) {
        notification.success({
          message: "Quizz updated",
          description: "The quizz has been successfully updated.",
        });

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description:
          getRequestErrorMessage(error) ||
          "An error has occured while updating the quizz.",
      });
      return error;
    }
  }

  useEffect(() => {
    async function fetchQuizz() {
      try {
        setIsLoading(true);
        if (!quizzId) throw new Error("Quizz ID invalid");

        const quizzList = await getQuizzList();

        if (!quizzList) throw new Error("Quizz list not found");

        const quizz = quizzList.find((q) => q.id === quizzId);

        if (!quizz) throw new Error("Quizz not found");

        form.setFieldsValue({
          title: quizz.title,
          subtitle: quizz.subtitle,
          template: quizz.template,
          room: (quizz.room as IRoom).id,
          eventName: quizz.eventName,
          rangeDate: [moment(quizz.displayAt), moment(quizz.endDisplayAt)],
        });

        setIsLoading(false);
      } catch (error) {
        notification.error({
          message: "An error has occured",
          description:
            getRequestErrorMessage(error) ||
            "An error has occured while fetching the quizz.",
        });
        return error;
      }
    }

    if (quizzId) fetchQuizz();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizzId]);

  return (
    <div className='basic-layout'>
      <Button
        type='link'
        icon={<LeftOutlined />}
        onClick={() => navigate(-1)}
        style={{ padding: 0 }}
      >
        {"Back"}
      </Button>
      <Spacer />

      <Flex align='center' justify='space-between'>
        <h1 style={{ margin: 0 }}>{quizzId ? "Edit Quizz" : "Create Quizz"}</h1>
        <div>
          <Flex gap={8}>
            {quizzId && (
              <Button onClick={handleDeleteQuizz} danger>
                {"Delete Quizz"}
              </Button>
            )}
            <Button
              type='primary'
              icon={quizzId ? <SaveOutlined /> : <PlusOutlined />}
              loading={isLoading}
              disabled={isLoading}
              onClick={() => form.submit()}
            >
              {quizzId ? "Save changes" : "Create and publish"}
            </Button>
          </Flex>
        </div>
      </Flex>
      <Spacer />

      <Card>
        <Form
          disabled={isLoading}
          form={form}
          onFinish={handleSubmit}
          layout='vertical'
        >
          <Form.Item label='Title' name='title' required>
            <Input placeholder='Title' />
          </Form.Item>
          <Form.Item label='Subtitle' name='subtitle' required>
            <Input placeholder='Subtitle' />
          </Form.Item>
          <Form.Item label='Room' name='room' required>
            <Select
              placeholder='Select a room'
              disabled={!roomList}
              loading={!roomList}
              style={{ width: "100%" }}
              options={roomList?.map((room) => ({
                label: room.name,
                value: room.id,
              }))}
              showSearch
              filterOption={searchFilterOptions}
            />
          </Form.Item>
          <Form.Item label='Template' name='template' required>
            <Input placeholder='Template' />
          </Form.Item>
          <Form.Item label='Display Dates' name='rangeDate' required>
            <DatePicker.RangePicker
              format='YYYY-MM-DD'
              style={{ width: "100%" }}
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item label='Event name' name='eventName'>
            <Input placeholder='Event name' />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default QuizzEdition;
