import { Link } from "react-router-dom";
import { ContentFormatsEnum } from "~/model";

export const CONTENT_TYPES_COL = [
  {
    title: "Main Specialty",
    dataIndex: ["specialty", "translations", "en"],
    key: "specialty",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text: string, record: { path: string }) => (
      <Link to={record.path}>{text}</Link>
    ),
  },
  {
    title: "Number of views",
    dataIndex: "views",
    key: "views",
    align: "center" as const,
    width: 150,
    render: (views: number) => views || 0,
  },
];

export const CONTENT_TYPES_TABS: Array<{
  type: ContentFormatsEnum;
  key: "articles" | "playlists" | "nectars" | "videos" | "infographics";
  title: string;
}> = [
  {
    type: ContentFormatsEnum.ARTICLE,
    key: "articles",
    title: "🗞️ Publications",
  },
  {
    type: ContentFormatsEnum.VIDEO,
    key: "videos",
    title: "📹 Videos",
  },
  {
    type: ContentFormatsEnum.NECTAR,
    key: "nectars",
    title: "🍯 Nectars",
  },
  {
    type: ContentFormatsEnum.PLAYLIST,
    key: "playlists",
    title: "🎬 Playlists",
  },
  {
    type: ContentFormatsEnum.INFOGRAPHIC,
    key: "infographics",
    title: "📊 Infographics",
  },
];
